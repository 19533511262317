import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import style from "./reward.module.scss";
import { CurrencyDecimalFormat } from "../../helper";
function Tiles(props) {
    console.log("Tiles ",props)
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const Currency = useSelector((state) => state.curr);
  useEffect(() => {
    let ewalletTiles = props.tiles && props.tiles.map((items) => {
      var background;
      var img;
      switch (items.type) {
        case "leg":
          background = style.green;
          img = "infinite-rewards.png";
          break;
        case "consumer_care_rewards":
          background = style.red;
          img = "consumer-care.png";
          break;
        case "cashback":
          img = "cash-back.png";
          background = style.violet;
          break;
        default:
          background = style.blue;
          img = "income-w.png";
      }
      // background = style.blue
      return {
        amount: items.amount,
        text: items.type,
        background: background,
        img: img,
      };
    });
    setData(ewalletTiles);
  }, []);

  return (
    <div className={`${style.ewalletTiles}`}>
      {data && data.map((tiles, index) => {
        console.log("tiles",data)
        return (
          <div
            className={`${
              currentTheme == "theme-dark"
                ? `${style.dark_mode_Tiles} d-flex`
                : `${style.Tiles} d-flex`
            }`}
            key={index}
          >
            <div className={`${style.leftIcon} ${tiles.background}`}>
              <img
                src={
                  require("../../../assets/images/logos/" + tiles.img).default
                }
                alt=""
              />
            </div>
            <div
              className={`${
                currentTheme == "theme-dark"
                  ? `${style.dark_mode_tilesData}`
                  : `${style.tilesData}`
              }`}
            >
              <h4>{t("Common." + tiles.text)}</h4>
              <span
                className={style.TilesAmount}
                title={(tiles.amount * Currency.value).toFixed(
                  Currency.precision
                )}
              >
                {Currency.currentCurr}{" "}
                {CurrencyDecimalFormat(
                  tiles.amount * Currency.value,
                  Currency.precision
                )}
              </span>
            </div>
          </div>
        );
      })}
    
    </div>
  );
}

export default Tiles;
