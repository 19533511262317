import { BASE_URL } from "../../configuration/apiconfig";
import UserStore from "../../store/UserStore";
import axios from "axios";
import API from "../../api/api";

export default class ReportService {
  key = localStorage.getItem("apiKey");

  getRewardReport = async (start=0,length=10,startDate="",endDate="",order="") => {
    console.log(start,length)
    return await API.API.get('report/rewardsReport?length='+length+'&start='+start+'&start_date='+startDate+'&end_date='+endDate+'&type='+order)
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };


  getVendorSalesReport = async (start=0,length=10,startDate="",endDate="",order="") => {
    console.log(start,length)
    return await API.API.get('report/vendorSalesReport?length='+length+'&start='+start+'&start_date='+startDate+'&end_date='+endDate+'&type='+order)
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  getPurchaseReport = async (start=0,length=10,startDate="",endDate="",order="") => {
    console.log(start,length)
    return await API.API.get('report/purchaseReport?length='+length+'&start='+start+'&start_date='+startDate+'&end_date='+endDate)
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  exportRewards = async () => {
    return await API.API.get('report/exportCashBack')
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };  
  
  exportVendorSalesReport = async () => {
    return await API.API.get('report/exportVendorSalesReport')
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  exportInfinite = async (startDate="",endDate="") => {
    console.log("exportInfinite",startDate,endDate)
    return await API.API.get('report/export-infinite-rewards/?start_date='+startDate+'&end_date='+endDate)
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  exportPurchaseReport  = async () => {
    return await API.API.get('report/exportPurchaseReport ')
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  getsubCustomer = async () => {
    return await API.API.get('report/subNodeReport')
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  

  deleteImage = async () => {
    const response = await API.API.get("profile/image_delete");
    if (response.status === 204) {
      return {
        status: true,
      };
    } else {
      const res = response;
      return res;
    }
  };

  UpdatePersonalDetails = async (data) => {
    return await API.API.put("profile/personalDetails", data)
      .then((res) => {
        if(res.status==200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => console.log(error));
  };

  UpdateContactDetails = async (data) => {
    return await API.API.put("profile/contactDetails", data)
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => console.log(error));
  };

  UpdateBankDetails = async (data) => {
    return await API.API.put("profile/bankDetails", data)
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => console.log(error));
  };

  UpdatePaymentDetails = async (data) => {
    return await API.API.put("profile/paymentMethod", data)
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => console.log(error));
  };

  UpdateSettings = async (data) => {
    return await API.API.put("profile/settingstDetails", data)
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => console.log(error));
  };

  upgradeDetails = async () => {
    return await API.API.get("profile/upgrade_package")
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => console.log(error));
  };

  getPaymentData = async (type) => {
    return await API.API.get("payment/payment_methods?type=" + type)
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => console.log(error));
  };

  getKYCDetails = async () => {
    return await API.API.get("profile/kyc_uploads")
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => console.log(error));
  };


  approvePending = async (formData) => {
    console.log(formData)
    return await API.API.post(`user/approve/${formData}`)
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => console.log(error));
  };

  uploadFile = async (formData) => {
    return await API.API.post("profile/kyc_file_uploads", formData)
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => console.log(error));
  };

  deletefile = async (id) => {
    return await API.API.post("profile/remove_kyc", id)
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => console.log(error));
  };

  subscriptionDetails = async () => {
    return await API.API.get("profile/subscription_details")
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => console.log(error));
  };

  uploadImage = async (image) => {
    return await API.API.post("profile/image", image)
      .then((res) => {
        if (res.status ==204) {
          return {
            status: true,
          };
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };

subscriptionSubmit = async (data) => {
  return await API.API.post("profile/subscription", data)
      .then((res) => {
        if (res.status==204) {
          return {
            status: true,
          };
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  }

  packageUpgradeSubmit = async (data)=>{
    return await API.API.post("upgrade/upgrade", data)
    .then((res) => {
      if (res.status ==204) {
      
        return {
          status: true ,
        };
      } else {
        return res;
      }
    })
    .catch((error) => console.log(error));
  }

  getSummaryReport =  async ()=>{
    return await API.API.get("report/summaryReport")
    .then((res) => {
      if (res.status ==200) {
        return res.data
      } else {
        return res;
      }
    })
    .catch((error) => console.log(error));
  }
  

    //change country name select state
    async countryChange(countryID) {
      return new Promise((resolve, reject) => {
        fetch(BASE_URL + "profile/countryChange?country_id=" + countryID, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "api-key": this.key,
            "access-token": UserStore.key,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }




  async getCaptcha() {
    const response = await fetch(
      BASE_URL + "profile/forget_transaction_password",
      {
        //url
        method: "GET", //method
        headers: {
          "api-key": this.key,
          "access-token": UserStore.key,
        },
      }
    );
    if (response.status === 200) {
      return response.blob();
    } else {
      const res = await response.json();
      return res;
    }
  }

 
}
