import React from 'react'
import successImg from '../../../assets/images/success.png'
import { Card } from 'react-bootstrap'
import style from './success.module.scss'
function Successmsg() {
    return (
        <div className='container'>
            <center>
                <div className={style.success}>

                    <Card style={{ width: '50%',padding:'5em'}}>
                        <Card.Body>

                            <img src={successImg} alt="noimage" style={{ width: '70px' }} />
                            <p>Transaction Completed Successfully</p>

                        </Card.Body>
                    </Card>

                </div>
            </center>

        </div>
    )
}

export default Successmsg