import React from "react";
import { useTranslation } from "react-i18next";
import { FormInputControl, DropDownBs } from "../common";
function NewHost(props) {
  const { t } = useTranslation();
  return (
    <div>
      <FormInputControl
        label={t("profile.firstName")}
        required={true}
        inputProps={{
          type: "text",
          value: props.form.firstName,
          name: "firstName",
          onChange: (e) => {
            props.changeHandler(e, true, props.keyValue);
          },
        }}
        error={props.form.FormError.firstName}
      />
      <FormInputControl
        label={t("profile.lastName")}
        required={true}
        inputProps={{
          type: "text",
          value: props.form.lastName,
          name: "lastName",
          onChange: (e) => {
            props.changeHandler(e, true, props.keyValue);
          },
        }}
        error={props.form.FormError.lastName}
      />
      <FormInputControl
        label={t("profile.address")}
        required={true}
        inputProps={{
          type: "text",
          value: props.form.address,
          name: "address",
          onChange: (e) => {
            props.changeHandler(e, true, props.keyValue);
          },
        }}
        error={props.form.FormError.address}
      />
      <FormInputControl
        label={t("Common.phone")}
        required={true}
        inputProps={{
          type: "text",
          value: props.form.phone,
          name: "phone",
          onChange: (e) => {
            props.changeHandler(e, true, props.keyValue);
          },
        }}
        error={props.form.FormError.phone}
      />
      <DropDownBs
        label={t("profile.country")}
        required={true}
        options={{
          data: props.country,
          value: "value",
          label: "code",
        }}
        DropdownProps={{
          onChange: (e) => {
            props.changeHandler(e, true, props.keyValue);
          },
          value: props.form.country,
          name: "country",
        }}
        error={props.form.FormError.country}
        preOption="country"
      />
      <DropDownBs
        label={t("profile.state")}
        required={true}
        options={{
          data: props.state,
          value: "value",
          label: "code",
        }}
        DropdownProps={{
          onChange: (e) => {
            props.dropdownChange(e, true, props.keyValue);
          },
          value: props.form.state,
          name: "state",
        }}
        error={props.form.FormError.state}
        preOption="state"
      />
      <FormInputControl
        label={t("profile.city")}
        required={true}
        inputProps={{
          type: "text",
          value: props.form.city,
          name: "city",
          onChange: (e) => {
            props.changeHandler(e, true, props.keyValue);
          },
        }}
        error={props.form.FormError.city}
      />
      <FormInputControl
        label={t("profile.email")}
        required={true}
        inputProps={{
          type: "text",
          value: props.form.email,
          name: "email",
          onChange: (e) => {
            props.changeHandler(e, true, props.keyValue);
          },
        }}
        error={props.form.FormError.email}
      />
      <FormInputControl
        label={t("profile.zipCode")}
        required={true}
        inputProps={{
          type: "text",
          value: props.form.zip,
          name: "zip",
          onChange: (e) => {
            props.changeHandler(e, true, props.keyValue);
          },
        }}
        error={props.form.FormError.zip}
      />
    </div>
  );
}

export default NewHost;
