import React, { useEffect, useState, useRef } from "react";
// import {Tabs,Tab} from 'react-bootstrap';
import { useTranslation } from "react-i18next";
//compoents
import Tabs from "react-responsive-tabs";
import style from "../../components/Reports/report.module.scss";
import { propTypes } from "react-bootstrap/esm/Image";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import { CurrencyDecimalFormat } from "../../helper";
import moment from "moment";
import DateFilter from "./DateFilter";





const stylees = {
    paddingTop: "10px",
    fontFamily: "system-ui",
};

export const LoadReport = (props) => {
  
const { currentTheme } = useSelector((state) => state.Theme);
const Currency = useSelector((state) => state.curr);
const {t} = useTranslation();

const [state, setState] = useState({ data: [] });
const [date, SetDate] = useState({
    start: moment().startOf("month").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
    selectedValues: [],
  });


useEffect(() => {
 
  }, [props.DataTables.data, setState]);


  const SubmitEvents = (start, end, type = "") => {

    SetDate((prev) => ({
      ...prev,
      start: start,
      end: end,
      selectedValues: type,
    }));
    props.filterHandler("", "", start, end);
  };

const columns = [
    {
      name: t("Common.orderId").toUpperCase(),
      selector: (row) => row.orderId,
      width: "15%",
     
    },
    {
      name: t("Common.memberName").toUpperCase(),
      selector: (row) => row.memberName,
    },
    {
      name: t("Common.paymentMethod").toUpperCase(),
      selector: (row) => row.paymentMethod,
    },
    {
      name: t("Common.quantity").toUpperCase(),
      selector: (row) => row.quantity,
    },
    {
      name: t("Common.total").toUpperCase(),
      selector: (row) => row.total,
      cell: (row) => (
        <span className={`${style.balanceAmount} ${style.badge}`}>
          {Currency.currentCurr}{" "}
          {(row.total * Currency.value).toFixed(Currency.precision)}
        </span>
      ),
    },
  ];

    return (
        <div className={style.summary}>
            <div
                className={`${currentTheme == "theme-dark"
                    ? `${style.dark_mode_tabContent}`
                    : `${style.tabContent}`
                    }`}
            >
                <div className={style.dataTable} style={stylees}>
                    <DateFilter
                     filterHandler={SubmitEvents}
                     resetHandler={props.resetData}
                     isSingle={false}
                     exports={true}
                     exportData={props.exportData}
                     printData={props.printData}
                    />
                    <DataTable
                        columns={columns}
                        data={props.DataTables.data}
                        highlightOnHover
                        // progressPending={ContentData.pending}
                        progressComponent={<div>Loading</div>}
                        persistTableHead
                        noHeader
                        pagination
                        paginationServer
                        responsive
                        paginationTotalRows={props.DataTables.rowsCount}
                        onChangeRowsPerPage={props.handlePerRowsChange}
                        onChangePage={props.paginationChange}
                        noDataComponent={t("Common.noDataRecordsToDisplay")}
                    //onSort={handleOnSort}
                    />
                </div>
            </div>
        </div>
    )

}