import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import Successmsg from '../../components/SafexpayMsg/Successmsg';
import companyName from '../../../store/mobxStore/companyName';

function Success() {
    const { t } = useTranslation();
    return (
        <div>
            <Helmet>
                <title>{companyName.companyName} | {t('Sidemenu.paymentsuccess')}</title>
            </Helmet>
            <Successmsg />
        </div>


    )
}

export default Success