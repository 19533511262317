import React, { useEffect, useState } from "react";
import EwalletService from "../../../service/ewallet/Ewallet";
import Table from "react-bootstrap/Table";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//service
import AppInfoservice from "../../../service/common/Appinfo";

//style
import { Row, Col } from "react-bootstrap";
import QRCode from "react-qr-code";

export class ComponentToPrint extends React.PureComponent {
  constructor(props) {
    console.log(props);
    super(props);
  }

  render() {
    return (
      <div
        style={{
          // height: "auto",
          margin: "20%",
          // maxWidth: 100,
          // width: "100%",
        }}
      >
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={this.props.data.ContentData}
          viewBox={`0 0 256 256`}
        />
      </div>
    );
  }
}

const QrcodePrintView = React.forwardRef((props, ref) => {
  const Currency = useSelector((state) => state.curr);
  const { t } = useTranslation();

  return <ComponentToPrint ref={ref} data={props} t={t} />;
});
export default QrcodePrintView;
