import React, { useState, useRef } from "react";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Multiselect } from "multiselect-react-dropdown";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "bootstrap-daterangepicker/daterangepicker.css";
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file
import style from "./report.module.scss";
import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";
//import UserEarningsPrintView from "./UserEarningsPrintView";
import ReportPrintView from './VendorReportPrintView'

function Filter({
  filterHandler,
  type,
  resetHandler,
  isSingle,
  ContentData,
  printData,
  loader,
  ...props
}) {
    const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const componentRef = useRef();
  const [state, setState] = useState({
    selectedValues: [],
    start: moment().startOf("month"),
    end: moment(),
    label:
      moment().startOf("month").format("MMMM D, YYYY") +
      " - " +
      moment().format("MMMM D, YYYY"),
  });
  const { start, end, label, selectedValues } = state;

  //customselect options
  const options = {
    Today: [moment().toDate(), moment().toDate()],
    "This Week": [moment().startOf("week"), moment().endOf("week").toDate()],
    "This Month": [
      moment().startOf("month").toDate(),
      moment().endOf("month").toDate(),
    ],
    "This Year": [
      moment().startOf("year").toDate(),
      moment().endOf("year").toDate(),
    ],
  };

  //datepicker select
  const handleCallback = (start, end) => {
    setState((prev) => ({
      ...prev,
      start: start,
      end: end,
      label: start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY"),
    }));
  };

  // multi select css components
  let styles = {
    multiselectContainer: {
      // To change css for multiselect (Width,height,etc..)
      minWidth: "10%",
      maxWidth: "max-content",
      display: "inline-block",
    },
    inputField: {
      // To change input field position or margin
      margin: "5px",
      width: "40px",
    },
    chips: {
      // To change css chips(Selected options)
      background: "rgb(228, 228, 228)",
      color: "rgb(88, 102, 110)",
      borderRadius: "10px",
      height: "20px",
    },
    optionContainer: {
      // To change css for option container
      boxShadow: "0px 1px 3px rgba(0,0,0,0.1)",
      border: "none",
    },
    searchBox: {
      // To change search box element look
      padding: 0,
      marginRight: "5px",
      paddingLeft: "5px",
    },
    option: {
      // To change css for dropdown options
      color: "black",
    },
  };
  //on select the multi select
  const selecthandler = (e) => {
    console.log("selected",e)
    setState((prev) => ({
      ...prev,
      selectedValues: e,
      label:
        moment().startOf("month").format("MMMM D, YYYY") +
        " - " +
        moment().format("MMMM D, YYYY"),
    }));
  };

  //remove selected items

  const removeHandler = (e) => {
    setState((prev) => ({
      ...prev,
      selectedValues: e,
      label:
        moment().startOf("month").format("MMMM D, YYYY") +
        " - " +
        moment().format("MMMM D, YYYY"),
    }));
  };

  //reset button click
  const resetValue = () => {
    setState((prev) => ({
      ...prev,
      selectedValues: [],
      start: moment().startOf("month"),
      end: moment(),
      label:
        moment().startOf("month").format("MMMM D, YYYY") +
        " - " +
        moment().format("MMMM D, YYYY"),
    }));
    resetHandler(
      start.format("YYYY-MM-DD"),
      end.format("YYYY-MM-DD"),
      selectedValues
    );
    // filterHandler(start.format('YYYY-MM-DD'),end.format('YYYY-MM-DD'),selectedValues);
  };

  const submitHandler = () => {
    filterHandler(
      start.format("YYYY-MM-DD"),
      end.format("YYYY-MM-DD"),
      selectedValues
    );
  };


  return (
    console.log("The data to print is here ",printData),
    <div className={style.filterSection}>
      {console.log("Filter section ",type)}

      <DateRangePicker
        initialSettings={{
          startDate: start.toDate(),
          endDate: end.toDate(),
          ranges: options,
        }}
        onCallback={handleCallback}
      >
        <div className={`${
            currentTheme == "theme-dark"
              ? `${style.dark_mode_DateRangePicker}`
              : `${style.DateRangePicker}`
          }`}>
          <i className="fa fa-calendar"></i>&nbsp;
          <span>{label}</span> <i className="fa fa-caret-down"></i>
        </div>
      </DateRangePicker>
      {/* <Multiselect
        singleSelect={isSingle}
        options={type}
        className="col-md-3 col-sm-12"
        showCheckbox={true}
        style={styles}
        onSelect={selecthandler}
        onRemove={removeHandler}
        displayValue="name"
        avoidHighlightFirstOption={true}
        placeholder={t("Common.type")}
        selectedValues={selectedValues}
        // hidePlaceholder={selectedValues.length===0?false:true}
      /> */}

      <div className={style.filterButton}>
        <Button
          variant="info"
          className={style.padding5}
          onClick={submitHandler}
        //   disabled={loader.EarningsTable.search}
        >
          {/* {loader.EarningsTable.search ? (
            <i
              className="fa fa-refresh fa-spin"
              style={{ marginRight: "5px" }}
            />
          ) : (
            ""
          )} */}

          {t("Button.search")}
        </Button>
        <Button className={style.padding5} onClick={resetValue} >
          {/* {loader.EarningsTable.reset ? (
            <i
              className="fa fa-refresh fa-spin"
              style={{ marginRight: "5px" }}
            />
          ) : (
            ""
          )} */}
          {t("Button.reset")}
        </Button>
      </div>
      <div className={style.exportButton}>
      <Button 
            onClick={() => {
              props.exportData("excel","CashBack Rewards");
            }}
          >
            {/* {loader.EarningsTable.excel ? (
              <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px", backgroundColor: "transparent" }}
              />
            ) : (
              <i className="fa fa-file-excel-o"></i>
            )} */}

            {t("Button.excel")}
          </Button>
          <Button 
            onClick={() => {
              props.exportData("csv","CashBack Rewards");
            }}
          >
            {/* {loader.EarningsTable.csv ? (
              <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px", backgroundColor: "transparent" }}
              />
            ) : (
              <i className="fa fa-file-text-o"></i>
            )} */}

            {t("Button.CSV")}
          </Button>

          <ReactToPrint
            trigger={() => (
              <Button>
                {/* {loader.EarningsTable.print ? (
              <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px", backgroundColor: "transparent" }}
              />
            ) : (
              <i className="fa fa-print"></i>
            )} */}
               
                {t("Button.print")}
              </Button>
            )}
            content={() => componentRef.current}
          />
             <div style={{ display: "none" }}>
            <ReportPrintView
              ref={componentRef}
              ContentData={printData}
              startDate={start.format("YYYY-MM-DD")}
              endDate={end.format("YYYY-MM-DD")}
        
            />
          </div> 

        </div>
      {/* {props.exports && (
        <div className={style.exportButton}>
          <Button disabled={loader.EarningsTable.excel}
            onClick={() => {
              props.exportData(
                start.format("YYYY-MM-DD"),
                end.format("YYYY-MM-DD"),
                selectedValues,
                "excel"
              );
            }}
          >
            {loader.EarningsTable.excel ? (
              <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px", backgroundColor: "transparent" }}
              />
            ) : (
              <i className="fa fa-file-excel-o"></i>
            )}

            {t("Button.excel")}
          </Button>
          <Button disabled={loader.EarningsTable.csv}
            onClick={() => {
              props.exportData(
                start.format("YYYY-MM-DD"),
                end.format("YYYY-MM-DD"),
                selectedValues,
                "csv"
              );
            }}
          >
            {loader.EarningsTable.csv ? (
              <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px", backgroundColor: "transparent" }}
              />
            ) : (
              <i className="fa fa-file-text-o"></i>
            )}

            {t("Button.CSV")}
          </Button>
          <ReactToPrint
            trigger={() => (
              <Button>
                {loader.EarningsTable.print ? (
              <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px", backgroundColor: "transparent" }}
              />
            ) : (
              <i className="fa fa-print"></i>
            )}
               
                {t("Button.print")}
              </Button>
            )}
            content={() => componentRef.current}
          />

          <div style={{ display: "none" }}>
            <UserEarningsPrintView
              ref={componentRef}
              ContentData={ContentData}
              startDate={start.format("YYYY-MM-DD")}
              endDate={end.format("YYYY-MM-DD")}
              filterValues={selectedValues}
            />
          </div> 
        </div>
      )} */}
    </div>
  );
}

export default Filter;
