import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import Helmet from "react-helmet";
import companyName from "../../../store/mobxStore/companyName";
import { useTranslation, withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import SalesReport from "../../components/Reports/SalesReport";
import moment from "moment";
import { connect } from "react-redux";
import PageTitle from "../../components/common/pageTitle";
import Spinner from "../../shared/Spinner";
import { useSelector } from "react-redux";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useHistory } from "react-router";
import UserStore from "../../../store/UserStore";
import ReportService from "../../../service/reports/reports";
import InfiniteRewards from "../../components/rewards/InfiniteRewards"
import style from "../../components/rewards/reward.module.scss";
import { InfiniteRewardsService } from "../../../service/rewards/rewards";
import Tiles from "../../components/rewards/Tiles";


const mapStateToProps = (state) => ({ lang: state.Lang });

const InfiniteReports = (props) => {
    console.log('InfiniteReports',props)
    const service = new ReportService();
    const Currency = useSelector((state) => state.curr);
    const { t } = props;
    const history = useHistory();
    const [state, setState] = useState({
        loading: true,
        DataTables: {
            totalRows: 0,
            data: [],
            pending: true,
            perPage: 10,
            inPage: 1,
            keys: [],
            startDate: moment().startOf("month").format("MMMM D, YYYY"),
            endDate: moment().format("MMMM D, YYYY"),
        },
        tilesData: [],
        printData: []
    });
    useEffect(() => {
        getInfiniteRewrds();
    }, [Currency.currentCurr]);

    const getInfiniteRewrds = (inPage = state.DataTables.inPage,
        perPage = state.DataTables.perPage,
        startDate = state.DataTables.startDate,
        endDate = state.DataTables.endDate,
        key = state.DataTables.keys) => {
        let start = (inPage - 1) * perPage;
        InfiniteRewardsService(start, perPage, startDate, endDate, key).then((res) => {
            if (res.status) {


                setState((prevState) => ({
                    ...prevState,
                    loading: false,
                    tilesData: res.data.totalpoints,
                    DataTables: {
                        ...prevState.DataTables,
                        data: res.data.result,
                        totalRows: res.data.count,
                        perPage: 10,
                        inPage: 1,
                        pending: true,
                    },
                }));
            }
            else {
                history.push('/logout')
            }
        })
    }
    const filterHandler = (inPage, perPage, start = "", end = "", key = "") => {
        setState((prevState) => ({
            ...prevState,
            DataTables: {
                ...prevState.DataTables,
                perPage: 10,
                inPage: 1,
                pending: true,
                startDate: start,
                endDate: end,
                keys: key,
            },
        }));
        getInfiniteRewrds(
            state.DataTables.inPage,
            state.DataTables.perPage,
            
            key
        );
    };
    const handlePerRowsChange = (
        inPage,
        perPage,
        start = "",
        end = "",
        key = ""
    ) => {
        setState((prevState) => ({
            ...prevState,
            DataTables: {
                ...prevState.DataTables,
                perPage: perPage,
                inPage: inPage,
                pending: true,
                startDate: start,
                endDate: end,
                keys: key,
            },
        }));
        getInfiniteRewrds(
            perPage,
            inPage,

            start,
            end,
            key
        );
    };
    const paginationChange = (
        inPage,
        perPage,
        start = "",
        end = "",
        key = ""
    ) => {
        setState((prevState) => ({
            ...prevState,
            DataTables: {
                ...prevState.DataTables,
                perPage: perPage,
                inPage: inPage,
                pending: true,
                startDate: start,
                endDate: end,
                keys: key,
            },
        }));
        getInfiniteRewrds(inPage, perPage, start, end, key);
    };
    const userfilterHandler = (key) => {
        setState((prevState) => ({
            ...prevState,
            DataTables: {
                ...prevState.DataTables,
                perPage: state.DataTables.perPage,
                inPage: state.DataTables.inPage,
                pending: true,
                startDate: state.DataTables.start,
                endDate: state.DataTables.end,
                keys: key,
            },
        }));
        getInfiniteRewrds(state.DataTables.inPage, state.DataTables.perPage, state.DataTables.start, state.DataTables.end, key);
    }
    const addCurrency = (data) => {
        let NewData = data.map((obj) => {

            if (obj.reward) {
                return {
                    ...obj,
                    reward: Currency.currentCurr + (obj.reward * Currency.value).toFixed(
                        Currency.precision
                    )
                }
            }
            else return obj
        })
        return NewData;
    }

    const exportData = (type, fileName) => {

        service.exportInfinite(state.DataTables.startDate, state.DataTables.endDate).then((res) => {
            let fileType =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            let fileExtension = ".xlsx";
            if (type === "excel") {
                const ws = XLSX.utils.json_to_sheet(addCurrency(res.data.result));
                XLSX.utils.sheet_add_aoa(ws, [
                    [
                        "DATE",
                        "SGA BEG.",
                        "SGB BEG.",
                        "SGA TRAN.",
                        "SGB TRAN.",
                        "SGA END.",
                        "SGB END",
                        "REWARD"
                    ],
                ]);

                const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

                const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
                const data = new Blob([excelBuffer], { type: fileType });
                console.log("data", ws)
                FileSaver.saveAs(data, fileName + fileExtension);
            }
            if (type === "csv") {
                fileExtension = ".csv";
                let fileType = "text/csv;charset=utf-8";
                const ws = XLSX.utils.json_to_sheet(addCurrency(res.data.result));
                XLSX.utils.sheet_add_aoa(ws, [
                    [
                        "DATE",
                        "SGA BEG.",
                        "SGB BEG.",
                        "SGA TRAN.",
                        "SGB TRAN.",
                        "SGA END.",
                        "SGB END",
                        "REWARD"
                    ],
                ]);
                const csvOutput = XLSX.utils.sheet_to_csv(ws);
                const data = new Blob([csvOutput], { type: fileType });
                FileSaver.saveAs(data, fileName + fileExtension);
            }
        });
    };
    console.log('keyssss',state.DataTables.keys)
    return (
        <div className={`h-100`}>
            <Helmet>
                <title>
                    {companyName.companyName} | {t("Sidemenu.leg")}
                </title>
            </Helmet>
            <PageTitle title="leg" buttonOn={false} />
            <div className={style.MainContainer}>
                {state.loading ? (
                    <Spinner />
                ) : (
                    <>
                        <div className={style.reawrd_subnode}>
                            <div className={style.inifinite_rewards}>
                                {state.tilesData &&
                                    <Tiles tiles={state.tilesData} />
                                }
                                <InfiniteRewards
                                    tableData={state.DataTables.data}
                                    ContentData={state.DataTables}
                                    rowsCount={state.DataTables.totalRows}
                                    filterHandler={filterHandler}
                                    handlePerRowsChange={handlePerRowsChange}
                                    paginationChange={paginationChange}
                                    exportData={exportData}
                                    printData={state.printData}
                                    keys={state.DataTables.keys}
                                />
                            </div>
                            <div className={style.subnode_rewards}>
                            <SalesReport filterHandler={userfilterHandler} />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default withTranslation()(
    connect(mapStateToProps)(withRouter(InfiniteReports))
);
