import React from 'react'
import { Helmet } from 'react-helmet'
import companyName from '../../../store/mobxStore/companyName'
import { useTranslation } from 'react-i18next'
import { Button } from 'react-bootstrap'
import style from './payment.module.scss'
function SafexpayModal(props) {
    console.log("safexpaymodallll", props)
    const { t } = useTranslation();

    const handleBuy=()=>{
        window.Buy(props.safexpayModalcontent);
    }







    // const handleBuy = () => {
    //     if (window.JsCheckout) {
    //         const jsCheckoutOptions = {
    //           merchantId: props.safexpayModalcontent.me_id
    //         };
      
    //         const spObj = new window.JsCheckout();
    //         spObj.Init(jsCheckoutOptions);
        
    //     const myJSON = JSON.stringify(props.safexpayModalcontent);
    //     // const enc = func_aes_encrypt(myJSON);
    //     const hash = props.safexpayModalcontent.hash;

    //     spObj.Pay(hash, myJSON, callbackForResponse);
    //     }
    // };
    // const handleBuy = () => {
    //     console.log("entered=====")
    //     // if (window.JsCheckout) {
    //         console.log('insidejscheckout')
    //       const jsCheckoutOptions = {
    //         merchantId:"202303090001"
    //       };
    //   console.log("windowwww",window)
    //       const spObj = new window.JsCheckout();
      
    //       // Initialize JsCheckout and invoke Pay inside the callback
    //       spObj.Init(jsCheckoutOptions, () => {
    //         const myJSON = JSON.stringify(props.safexpayModalcontent);
    //         console.log("myjosnnn",myJSON)
    //         const hash = props.safexpayModalcontent.hash;
      
    //         spObj.Pay(hash, myJSON, callbackForResponse);
    //       });
    //     // }
    //   };
      
    // const callbackForResponse = (response) => {
    //     console.log("ResponseInCallback:::", response);
    // };
    return (
        <div>
           
            <Helmet>
                <title>
                    {companyName.companyName} | {t("Sidemenu.ewallet")}
                </title>
            </Helmet>

            <Button onClick={handleBuy} id="buy"  className={style.buyBtn}>
                Pay Now
            </Button>
        </div>
    )
}

export default SafexpayModal
