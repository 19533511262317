import React, { useEffect, useState } from "react";
import style from "../tree/tree.module.scss";
// import { TitleHeaders } from "./";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Spinner from "../../shared/Spinner";
import { IMG_URL } from "../../../configuration/apiconfig";
import Image from "../../../assets/images/nophoto/no_photo.jpg";
import { useSelector } from "react-redux";
import ReportService from "../../../service/reports/reports";
import AlertMessage from "../common/AlertMessage";
import Links from "../dashboard/Links"
const SalesReport = ({ filterHandler }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { currentTheme } = useSelector((state) => state.Theme);
  const project = useSelector((state) => state.ProjectConfig);
  const service = new ReportService();
  const [state, setState] = useState({
    level: "all",
    offset: 0,
    data: [],
    count: 0,
    totalLevels: [],
    showNext: true,
    loading: true,
    searchloader: false,
    page_count: 0,
    notify: {
      message: '',
      notifyDissmissed: '',
      type: '',
      header: '',
      show: false
    }
  });
  useEffect(() => {
    service.getsubCustomer(state.level, state.offset).then((res) => {
      if (res.status) {
        console.log(res)
        setState((prev) => ({
          ...prev,
          data: res.data.table_data,
          loading: false,
        }));
      } else {
        history.push("/login");
      }
    });
  }, [state.level, state.offset, history]);

  const goToPreviousPath = () => {
    history.goBack();
  };
  const OnApprove = (id) => {
    service.approvePending(id).then((res) => {
      if (res.status) {
        window.open(`${res.data.url}`);
        service.getsubCustomer(state.level, state.offset).then((res) => {
          if (res.status) {
            console.log(res)
            setState((prev) => ({
              ...prev,
              data: res.data.table_data,
              loading: false,
            }));
          } else {
            history.push("/login");
          }
        });

      }
      else {
        setState({
          ...state,
          notify: {
            show: true,
            type: "danger",
            message: "Activate Unsuccessful",
          },
        });

      }
    })
  }

  const dissmissNotify = () => {
    setState((prev) => ({
      ...prev,
      notify: {
        ...prev.notify,
        show: false,
      },
    }));
  };
  //onreset button click
  const reset = () => {
    setState((prev) => ({
      ...prev,
      level: "",
      offset: 0,
    }));
  };
  //serch the level wise list
  const searchLevel = (level) => {
    setState((prev) => ({
      ...prev,
      searchloader: true,
    }));

    setTimeout(() => {
      setState((prev) => ({
        ...prev,
        level: level,
        searchloader: false,
      }));
    }, 1000);
  };
  const handlePageClick = (data) => {
    let selected = data.selected;
    let offset = Math.ceil(selected * 20);
    let showNext = true;
    if (offset > 0) {
      showNext = Math.floor(state.count / offset) === 1 ? false : true;
    }
    setState((prev) => ({
      ...prev,
      offset: offset,
      showNext: showNext,
    }));
  };

  const checkboxHandler = (e, id, username) => {
    console.log('usernamee',username)
    let NewData = state.data.map((obj, index) => {
      if (id == index) {
        return {
          ...obj,
          checked: !obj.checked
        }
      }
      else {
        return {
          ...obj,
          checked: false
        }
      }
    })
    setState((prev) => ({
      ...prev,
      data: NewData,
    }));
  let Filter =  NewData.some((obj)=>{return obj.checked})
  if(Filter){
    filterHandler(username)
  }
  else{
    filterHandler(NewData[0].id)
  }
  }

  return (
    <div className={style.DownlineMembers}>
      {
        state.notify.show &&
        <AlertMessage
          message={state.notify.message}
          dismiss={dissmissNotify}
          type={state.notify.type}
          header={state.notify.header}
          show={state.notify.show}
        />
      }
      {state.loading ? (
        <Spinner />
      ) : (
        <>
          {history.location.state &&
            history.location.state.prevPath == "/genealogyTree" ? (
            <button className={style.back_btn} onClick={goToPreviousPath}>
              Back
              <i className=" btn_icon fa fa-backward"></i>
            </button>
          ) : (
            <></>
          )}
          <div>
            <Table hover responsive className={style.tableData}>
              <thead>
                <tr>
                  <th></th>
                  <th>{t("Common.memberName")}</th>
                  {state.data[0]?.placement && <th>{t("Common.placement")}</th>}
                  <th>{t("Common.position")}</th>
                  <th>{t("Common.level")}</th>
                  <th>{t("Common.action")}</th>
                </tr>
              </thead>
              <tbody>
                {state.data.length > 0 ? (
                  state.data.map((items, index) => (

                    <tr key={index}>
                      <td><input type="checkbox" onChange={(e) => checkboxHandler(e, index, items.id)} checked={items?.checked} disabled={!items.active}></input></td>
                      <td className={style.tableAvatarDetails}>
                        {/* <input type="checkbox" disabled={style.active}></input> */}
                        <div>

                          <img
                            src={items.user_photo ? items.user_photo : Image}
                            alt="No Image"
                            className={style.profileImage}
                          />
                          <span
                            className={`${style.active} ${items.active
                                ? style.active
                                : style.inactive
                              }`}
                          ></span>
                        </div>
                        <div>
                          <div className={style.userName}>
                            {/* {items.fullName}  */}
                          </div>
                          <span className={style.userid}>{items.username}</span>
                        </div>
                      </td>
                      {items.placement && <td>{items.placement}</td>}
                      <td>{items.position == 'L' ? "A" : items.position == 'R' ? "B" : ""}</td>
                      <td>{items.userLevel}</td>
                      <td>
                        {items.active ?
                          <div className={style.report_action_button} style={{ display: 'flex' }}>
                            <div>

                              <Link
                                to={`/genealogyTree/${items.username}`}
                                className={`${currentTheme == "theme-dark"
                                    ? `${style.themedarkaction}`
                                    : `${style.themelightaction}`
                                  }`}

                              >
                                <div className={style.tooltip}>
                                  <i className="fa fa-sitemap">
                                    <span className={style.tooltiptext}>
                                      {t("Common.viewgene")}
                                    </span>
                                  </i>
                                </div>
                              </Link>
                            </div>
                            <div>
                              <Links style={{ marginRight: '10px' }}
                                iscopy={true}
                                icon={'fa fa-files-o'}
                                key={index}
                                link={items.replica_url}
                              />
                            </div>
                          </div>
                          :
                          <Button onClick={() => OnApprove(items.id)}>Activate</Button>

                        }
                      </td>
                    </tr>
                  ))
                ) : (
                  <div className={`${style.NodataImage}`}>
                    <img
                      src={IMG_URL + "logos/no-datas-found.png"}
                      alt="nodata"
                      style={{ width: "50%" }}
                    />
                  </div>
                )}
              </tbody>
            </Table>
          </div>
        </>
      )}
    </div>
  );
};

export default SalesReport;
