import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import companyName from "../../../store/mobxStore/companyName";
import { useTranslation, withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import moment from "moment";
import { connect } from "react-redux";
// import fileDownload from 'js-file-download';
//components
import PageTitle from "../../components/common/pageTitle";
import { Tiles, Content, ModalForms } from "../../components/ewallet";
import Spinner from "../../shared/Spinner";
import { useSelector } from "react-redux";
//service
import ReportService from "../../../service/reports/reports";
import UserStore from "../../../store/UserStore";
import style from "../../components/Reports/report.module.scss";
import { SummaryReport } from "../../components/Reports";

const mapStateToProps = (state) => ({ lang: state.Lang });
const EwalletReport = (props) => {
  const Currency = useSelector((state) => state.curr);
  const { t } = props;
  const service = new ReportService();
  const [state, setState] = useState({
    loading: true,
    DataTables: {
      summaryReport: {
        totalRows: 0,
        data: [],
        totalPoints: [],
        pending: true,
        perPage: 10,
        inPage: 1,
        startDate: moment().subtract(29, "days"),
        endDate: moment(),
        direction: "",
      },
    },
  });

  const getSummaryReport = () => {
    service.getSummaryReport().then((res) => {
      setState({
        ...state,
        DataTables: {
          ...state.DataTables,
          summaryReport: {
            ...state.summaryReport,
            data: res.data.result,
            totalPoints: res.data.totalpoints,
          },
        },
      });
    });
  };

  useEffect(() => {
    getSummaryReport();
  }, []);

  return (
    <div className={`h-100`}>
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.RewardSummaryReport")}
        </title>
      </Helmet>
      <PageTitle title="RewardSummaryReport" buttonOn={false} />
      <div className={style.MainContainer}>
        <SummaryReport
          tableData={state.DataTables.summaryReport}
          rowsCount={state.DataTables.summaryReport.rowsCount}
        />
      </div>
    </div>
  );
};

export default withTranslation()(
  connect(mapStateToProps)(withRouter(EwalletReport))
);
