import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import Helmet from "react-helmet";
import companyName from "../../../store/mobxStore/companyName";
import { useTranslation, withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import SalesReport from "../../components/Reports/SalesReport";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
// import fileDownload from 'js-file-download';
//components
import PageTitle from "../../components/common/pageTitle";
import { Tiles, Content, ModalForms } from "../../components/ewallet";
import { FormInputControl } from "../../components/common";
import Spinner from "../../shared/Spinner";
import { useSelector } from "react-redux";

//service
import EwalletService from "../../../service/ewallet/Ewallet";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useHistory } from "react-router";
import UserStore from "../../../store/UserStore";
import style from "../../components/Reports/report.module.scss";
import { VendorReport, RewardTiles } from "../../components/Reports";
import ReportService from "../../../service/reports/reports";

const mapStateToProps = (state) => ({ lang: state.Lang });

const VendorSales = (props) => {
  const service = new ReportService();
  const Currency = useSelector((state) => state.curr);

  const { t } = props;
  const [checkout, setCheckout] = useState(false);
  const [EwalletAmount, setEwalletAmount] = useState({
    amount: "",
    FormError: {
      amount: "",
    },
    isvalid: false,
  });

  const [loader, setLoader] = useState({
    EarningsTable: {
      search: false,
      reset: false,
      excel: false,
      csv: false,
      print: false,
    },
    HistoryTable: {
      search: false,
      reset: false,
    },
  });

  const history = useHistory();

  const changeHandler = (e) => {
    const { name, value } = e.target;

    setEwalletAmount((prev) => ({
      ...prev,
      [name]: value,
      FormError: {
        ...prev.FormError,
        [name]: "",
      },
      isvalid: false,
    }));
  };

  const [state, setState] = useState({
    loading: true,
    DataTables: {
      totalRows: 0,
      data: [],
      pending: true,
      perPage: 10,
      inPage: 1,
      keys: [],
      startDate: moment().startOf("month").format("MMMM D, YYYY"),
      endDate: moment().format("MMMM D, YYYY"),
    },
    tilesData: [],
    printData: [],
  });

  useEffect(() => {
    loadReport();
  }, [Currency.currentCurr]);

  useEffect(() => {
    getPrintData();
  }, []);

  const getPrintData = () => {
    service.exportVendorSalesReport().then((res) => {
      if (res.status) {
        setState((prev) => ({
          ...prev,
          printData: res.data.result,
        }));
      }
    });
  };

  //load the data
  const loadReport = (
    inPage = state.DataTables.inPage,
    perPage = state.DataTables.perPage,
    startDate = state.DataTables.startDate,
    endDate = state.DataTables.endDate,
    key = state.DataTables.keys
  ) => {
    let start = (inPage - 1) * perPage;
    console.log(start, perPage, startDate, endDate, key);
    service
      .getVendorSalesReport(start, perPage, startDate, endDate, key)
      .then((res) => {
        if (res.status) {
          console.log("responseonfilter", res.data.table_data);
          setState((prevState) => ({
            ...prevState,
            loading: false,
            tilesData: res.data.total,
            DataTables: {
              data: res.data.result.table_data,
              totalRows: res.data.result.count,
              perPage: 10,
              inPage: 1,
              pending: true,
            },
          }));
        } else {
          props.history.push("/logout");
        }
      });
  };

  const filterHandler = (inPage, perPage, start = "", end = "", key = "") => {
    setState((prevState) => ({
      ...prevState,
      DataTables: {
        ...prevState.DataTables,
        perPage: 10,
        inPage: 1,
        pending: true,
        startDate: start,
        endDate: end,
        keys: key,
      },
    }));
    loadReport(
      state.DataTables.inPage,
      state.DataTables.perPage,
      start,
      end,
      key
    );
  };
  const handlePerRowsChange = (
    inPage,
    perPage,
    start = "",
    end = "",
    key = ""
  ) => {
    setState((prevState) => ({
      ...prevState,
      DataTables: {
        ...prevState.DataTables,
        perPage: perPage,
        inPage: inPage,
        pending: true,
        startDate: start,
        endDate: end,
        keys: key,
      },
    }));
    loadReport(
      perPage,
      inPage,

      start,
      end,
      key
    );
  };

  const paginationChange = (
    inPage,
    perPage,
    start = "",
    end = "",
    key = ""
  ) => {
    setState((prevState) => ({
      ...prevState,
      DataTables: {
        ...prevState.DataTables,
        perPage: perPage,
        inPage: inPage,
        pending: true,
        startDate: start,
        endDate: end,
        keys: key,
      },
    }));

    loadReport(inPage, perPage, start, end, key);
  };

  const addCurrency = (data) => {
    let NewData = data.map((obj) => {
      if (obj) {
        return {
          ...obj,
          total:
            Currency.currentCurr +
            (obj.total * Currency.value).toFixed(Currency.precision),
          totalWithOutVat:
            Currency.currentCurr +
            (obj.totalWithOutVat * Currency.value).toFixed(Currency.precision),
        };
      } else return obj;
    });
    return NewData;
  };

  const exportData = (type, fileName) => {
    service.exportVendorSalesReport().then((res) => {
      let fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let fileExtension = ".xlsx";

      if (type === "excel") {
        const ws = XLSX.utils.json_to_sheet(addCurrency(res.data.result));
        XLSX.utils.sheet_add_aoa(ws, [
          [
            "DATE",
            "NAME",
            "TRANSACTION NO.",
            "TOTAL AMOUNT",
            "AMOUNT WITHOUT VAT",
            "VAT",
            "VENDOR SHARE",
          ],
        ]);

        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        console.log("data", ws);
        FileSaver.saveAs(data, fileName + fileExtension);
      }
      if (type === "csv") {
        fileExtension = ".csv";
        let fileType = "text/csv;charset=utf-8";
        const ws = XLSX.utils.json_to_sheet(addCurrency(res.data.result));
        XLSX.utils.sheet_add_aoa(ws, [
          [
            "DATE",
            "NAME",
            "TRANSACTION NO.",
            "TOTAL AMOUNT",
            "AMOUNT WITHOUT VAT",
            "VAT",
            "VENDOR SHARE",
          ],
        ]);
        const csvOutput = XLSX.utils.sheet_to_csv(ws);
        const data = new Blob([csvOutput], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
      }

      if (type === "print") {
        // exportData(res.data.result, "yo", type);
        // setState((prev) => ({
        //   ...prev,
        //   printData: res.data.result
        // }))
      }

      // if(type==='excel'){
      //     fileDownload(res, 'User Earnigs Report.xlsx');
      // }
    });
  };
  return (
    console.log("print data in vendor report is ", state.printData),
    (
      <div className={`h-100`}>
        <Helmet>
          <title>
            {companyName.companyName} | {t("Sidemenu.vsr")}
          </title>
        </Helmet>
        <PageTitle title="vsr" buttonOn={false} />
        <div className={style.MainContainer}>
          {state.loading ? (
            <Spinner />
          ) : (
            <>
              {state.tilesData && <RewardTiles tiles={state.tilesData} />}

              <VendorReport
                tableData={state.DataTables.data}
                ContentData={state.DataTables}
                rowsCount={state.DataTables.totalRows}
                filterHandler={filterHandler}
                handlePerRowsChange={handlePerRowsChange}
                paginationChange={paginationChange}
                exportData={exportData}
                printData={state?.printData}
              />
            </>
          )}
        </div>
      </div>
    )
  );
};

export default withTranslation()(
  connect(mapStateToProps)(withRouter(VendorSales))
);
