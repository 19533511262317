import React, { useEffect, useState } from "react";
import EwalletService from "../../../service/ewallet/Ewallet";
import Table from "react-bootstrap/Table";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//service
import AppInfoservice from "../../../service/common/Appinfo";

//style
import { Row, Col } from "react-bootstrap";
import ReportService from "../../../service/reports/reports";

export class ComponentToPrint extends React.PureComponent {
    constructor(props) {
        super(props);
        this.service = new ReportService();

        const current = new Date();
        const date = `${current.getDate()}/${current.getMonth() + 1
            }/${current.getFullYear()} , ${current.getHours()}:${current.getMinutes()}`;
        // const time = current.getHours() + ':' + current.getMinutes();
        this.state = {
            earnings: [],
            loading: true,
            date: date,
        };
    }



    exportDatas(type) {
console.log(this.props)
        this.service.exportInfinite(this.props.data.startDate,this.props.data.endDate).then((res) => {
            if (res.status) {
                this.setState((prevState) => ({
                    ...prevState,
                    loading: false,
                    earnings: res.data.result,
                }));
            } else {
                this.setState((prevState) => ({
                    ...prevState,
                    loading: true,
                }));
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.data.startDate != prevProps.data.startDate || this.props.data.endDate !=prevProps.data.endDate
        ) {
            this.exportDatas("print");
        }
    }
    componentDidMount() {
        this.exportDatas("print");
    }

    render() {
        return (
            <div style={{ padding: 60 }}>
                <Row>
                    <Col>{this.state.date}</Col>
                    <Col>
                        <h5 style={{ textAlign: "center" }}>Infinite Rewards</h5>
                    </Col>
                </Row>

                <div ref={this.ref}>
                    <Row style={{ marginBottom: 50 }}>
                        <Col span={8}>
                            {/* <img src={this.props.company.logo} alt="Logo" /> */}
                            <div>COMPANY NAME : {this.props.company.comapny_name}</div>
                            <div>COMPANY ADDRESS : {this.props.company.company_address}</div>
                            <div>TELEPHONE : {this.props.company.phone}</div>
                            <div>EMAIL : {this.props.company.email}</div>
                        </Col>
                    </Row>
                    {!this.state.loading && this.state.earnings?.length > 0 && (
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th>{this.props.t("Common.Date")}</th>
                                    <th>SGA BEG.</th>
                                    <th>SGB BEG.</th>
                                    <th>SGA TRAN.</th>
                                    <th>SGB TRAN.</th>
                                    <th>SGA END.</th>
                                    <th>SGB END.</th>
                                    <th>Rewards</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.earnings.map((obj, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{obj.date}</td>
                                            <td>{obj.startingLeftLeg}</td>
                                            <td>{obj.startingRightLeg}</td>
                                            <td>{obj.transactionLeft}</td>
                                            <td>{obj.transactionRight}</td>
                                            <td>{obj.endingLeft}</td>
                                            <td>{obj.endingRight}</td>
                                            <td>
                                                {" "}
                                                {this.props.Currency.currentCurr}
                                                {(
                                                    parseFloat(obj.reward) *
                                                    this.props.Currency.value
                                                ).toFixed(this.props.Currency.precision)}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    )}
                </div>
            </div>
        );
    }
}

const InfinitePrint = React.forwardRef((props, ref) => {

    console.log("frowardref",props)
    const Currency = useSelector((state) => state.curr);
    const { t } = useTranslation();

    const [companyDetails, setCompanyDetails] = useState({});

    const service = new AppInfoservice();

    useEffect(() => {
        service.Appinfo().then((res) => {
            if (res.status) {
                setCompanyDetails((prevState) => ({
                    ...prevState,
                    logo: res.data.company_info.login_logo,
                    comapny_name: res.data.company_info.company_name,
                    company_address: res.data.company_info.company_address,
                    phone: res.data.company_info.phone,
                    email: res.data.company_info.email,
                }));
            }
        });
    }, []);
    return (
        <ComponentToPrint
            ref={ref}
            data={props}
            company={companyDetails}
            Currency={Currency}

            t={t}
        />
    );
});
export default InfinitePrint;
