import React, { useState } from "react";
import { useEffect } from "react";
import { Table } from "react-bootstrap";
import style from "./reward.module.scss";
import { RowExpand } from "../../../service/rewards/rewards";
import moment from "moment";

export const Expand = ({ Date,userid }) => {
  console.log('useridgghg',userid)
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const start=0
    const length=10
    RowExpand(moment(Date).format("MM-DD-YYYY"),start,length,userid).then((res) => {
      console.log('kyessssggg',userid)
      if (res.status) {
        setLoading(false);
        setState(res.data.result);
      } else {
        setLoading(false);
      }
    });
  };
  if (loading) {
    return <div>Loading</div>;
  } else {
    return (
      <div className={style.expanded_table}>
        <Table bordered hover className={style.Table}>
          <tr>
            <th rowSpan={2}>Transaction Date & Time</th>
            <th colSpan={2}>Begining Balance</th>
            <th colSpan={2}>Transaction Balance</th>
            <th colSpan={2}>Ending Balance </th>
          </tr>
          <tr className={style.rowending_border}>
            <th>SGA</th>
            <th>SGB</th>
            <th>SGA</th>
            <th>SGB</th>
            <th>SGA</th>
            <th>SGB</th>
          </tr>
          {state.map((obj, index) => {
            return (
              <tr>
                <th>{obj.date}</th>
                <td>{obj.startingLeftLeg}</td>
                <td>{obj.startingRightLeg}</td>
                <td>{obj.transactionLeft}</td>
                <td>{obj.transactionRight}</td>
                <td>{obj.endingLeft}</td>
                <td>{obj.endingRight}</td>
              </tr>
            );
          })}
        </Table>
      </div>
    );
  }
};
