import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { PaymentMethods } from "../../components/common";
import style from "../../components/registration/registration.module.scss";

function PaymentEwallet(props) {
  console.log("PaymentEwallet", props.data);
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const currency = useSelector((state) => state.curr);
  // let amount= parseFloat(props.productAmount)+parseFloat(props.data.registrationFee);
  let amount =
    parseFloat(props.productAmount) + parseFloat(props.data.registrationFee);
  return (
    <div
      className={`${
        currentTheme == "theme-dark"
          ? `${style.dark_mode_paymentMethod}`
          : `${style.paymentMethod}`
      }`}
    >
      {/* <div className={style.totalAmount}>
                <h4>{t('Common.totalAmount')} : {currency.currentCurr} {(amount * currency.value).toFixed(currency.precision)}</h4>
            </div> */}
      {/* //onSubmit={(e)=>{props.submit(e,'payment')}} */}
      {/* <Form onSubmit={(e)=>{props.submit(e,'payment')}} > */}

      <PaymentMethods
        methods={props.data}
        submit={(e, token) => {
          props.submit(e, token, "payment");
        }}
        selectedTab={0}
        // prevButtonClick={props.prevButtonClick}
        // methodChange={props.paymentMethodChange}
        // username={props.userName}
        // product={props.product}
        amount={amount}
        payment_type={props.payment_type}
        {...props}
      />
      {/* </Form> */}
    </div>
  );
}

export default PaymentEwallet;
