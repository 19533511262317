import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import style from "./report.module.scss";
import { CurrencyDecimalFormat } from "../../helper";
function Salestiles(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const Currency = useSelector((state) => state.curr);
  useEffect(() => {
    // let ewalletTiles = props.tilesData.map((items) => {
    //   var background;
    //   var img;
    //   switch (items.text) {
    //     case "credited":
    //       background = style.green;
    //       img = "income-w.png";
    //       break;
    //     case "debited":
    //       background = style.red;
    //       img = "Bonus-w.png";
    //       break;
    //     case "ewalletBalance":
    //       img = "E-Wallet-w.png";
    //       background = style.violet;
    //       break;
    //     case "purchaseWallet":
    //       img = "income-w.png";
    //       background = style.pink;
    //       break;
    //     case "commissionEarned":
    //       img = "income-w.png";
    //       background = style.blue;
    //       break;
    //     default:
    //       background = style.blue;
    //       img = "income-w.png";
    //   }
    //   // background = style.blue
    //   return {
    //     amount: items.amount,
    //     text: items.text,
    //     background: background,
    //     img: img,
    //   };
    // });
    // setData(ewalletTiles);
  }, []);
//   const removeData = (data) => {
//     let array = [];
//     for (let i = 0; i < data.length; i++) {
//       if (props.repurchase_status == "yes") {
//         array.push(data[i]);
//       } else if (
//         props.repurchase_status == "no" &&
//         data[i].text != "purchaseWallet"
//       ) {
//         array.push(data[i]);
//       } else {
//         // array.push(data[i])
//       }
//     }
//     return array;
//     // if(props.repurchase_status=='no'){
//     // array = data.filter((obj)=> return obj.text!='purchaseWallet'
//     // }
//   };
  return (
    <div className={`${style.ewalletTiles}`}>
          <div
            className={`${
              currentTheme == "theme-dark"
                ? `${style.dark_mode_Tiles} d-flex`
                : `${style.Tiles} d-flex`
            }`}
          >
            <div className={`${style.leftIcon}`}>
              <img
                src={
                  require("../../../assets/images/logos/income-w.png" ).default
                }
                alt=""
              />
            </div>
            <div
              className={`${
                currentTheme == "theme-dark"
                  ? `${style.dark_mode_tilesData}`
                  : `${style.tilesData}`
              }`}
            >
              <h4>{t("Common." + 'TotalSales')}</h4>
              <span
                className={style.TilesAmount}
                // title={(tiles.amount * Currency.value).toFixed(
                //   Currency.precision
                // )}
              >
                {Currency.currentCurr}{" "}
                10000
                {/* {CurrencyDecimalFormat(
                  tiles.amount * Currency.value,
                  Currency.precision
                )} */}
              </span>
            </div>
            
          </div>
          <div
            className={`${
              currentTheme == "theme-dark"
                ? `${style.dark_mode_Tiles} d-flex`
                : `${style.Tiles} d-flex`
            }`}
          >
            <div className={`${style.leftIcon}`}>
              <img
                src={
                  require("../../../assets/images/logos/income-w.png" ).default
                }
                alt=""
              />
            </div>
            <div
              className={`${
                currentTheme == "theme-dark"
                  ? `${style.dark_mode_tilesData}`
                  : `${style.tilesData}`
              }`}
            >
              <h4>{t("Common." + 'TotalOrders')}</h4>
              <span
                className={style.TilesAmount}
                // title={(tiles.amount * Currency.value).toFixed(
                //   Currency.precision
                // )}
              >
                {Currency.currentCurr}{" "}
                10000
                {/* {CurrencyDecimalFormat(
                  tiles.amount * Currency.value,
                  Currency.precision
                )} */}
              </span>
            </div>
            
          </div>
        
    
    </div>
  );
}

export default Salestiles;
