import React from 'react'
import { FormInputControl } from '../common'
import { useTranslation } from 'react-i18next'
import { useState } from 'react';
import { isNumber } from '../../validation';
import { Button } from 'react-bootstrap';
function Safexpay(props) {
    console.log("safexpay", props)
    const { t } = useTranslation();

    const [state, setstate] = useState({
        amount: "",
        FormError: {
            amount: ""
        },


    })
    const { FormError } = state

    const textChangeHandler = (e) => {

        const { name, value } = e.target
        console.log("valuee", value)
        setstate({
            ...state,
            [name]: value
        })
        validation(name)
        props.getSafexpay(value)
    }

    const validation = (value) => {
        const { amount } = state
        console.log("amounttttt", amount)
        switch (value) {
            case "amount":
                if (!isNumber(amount)) {
                    setstate((prev) => ({
                        ...prev,
                        FormError: {
                            ...prev.FormError,
                            amount: t("validation.onlynumbersalloweded")
                        }
                    }))
                } else if (amount < 0) {

                    setstate((prev) => ({
                        ...prev,
                        FormError: {
                            ...prev.FormError,
                            amount: t("validation.posativenumbersallowed")
                        }
                    }))
                } else {
                    console.log("entered")
                    setstate((prev) => ({
                        ...prev,
                        FormError: {
                            ...prev.FormError,
                            amount: ""
                        }
                    }))
                }
                break;

            default:
                break;
        }
    }
    return (
        <div className='mt-4'>
            <FormInputControl
                label={t("ewallet.amount")}
                isRequired={true}
                error={{
                    error: FormError.amount,
                }}
                inputProps={{
                    name: "amount",
                    onChange: textChangeHandler,
                    value: state.amount,
                    placeholder: t("ewallet.amount"),


                }}
            />
            <Button disabled={!state.amount || props.loader} onClick={props.submit}>
                {
                    props.loader && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px" }}
                        />
                    )
                }
                {t('Button.finish')}
            </Button>
        </div>
    )
}

export default Safexpay