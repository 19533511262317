import React, { useEffect, useState, useRef, Fragment } from "react";
// import {Tabs,Tab} from 'react-bootstrap';
import { useTranslation } from "react-i18next";
//compoent
import style from "./reward.module.scss";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import Filter from "./Filter";
import { CurrencyDecimalFormat } from "../../helper";
import moment from "moment";
import { Expand } from "./Expand";
import InfiniteFilter from "./InfiniteFilter";

const stylees = {
  paddingTop: "10px",
  fontFamily: "system-ui",
};
export const Title = ({ columname, columsubname }) => {
  return (
    <table className=" table_reward_tabl">
      <tr className="begining_balance">
        <th colspan="2">{columname}</th>
      </tr>
      <tr>
        <th>{columsubname.column1}</th>
        <th>{columsubname?.column2}</th>
      </tr>
    </table>
  );
};

function
  InfiniteRewards({
    tableData,
    filterHandler,
    rowsCount,
    handlePerRowsChange,
    paginationChange,
    ContentData,
    exportData,
    printData,
    keys
  }) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();

  const Currency = useSelector((state) => state.curr);
  const [date, SetDate] = useState({
    start: moment().startOf("month").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
    selectedValues: [],
    expandedDate: "",
    userId:keys
  });
  let ColumnSub = {
    column1: "SGA",
    column2: "SGB",
  };
  const columns = [
    {
      //t("Common.Date").toUpperCase()
      name: <Title columname={"DATE"} columsubname={""} />,
      selector: (row) => row.date,
      // width: "20%",
      sortable: true,
      id: "table_reward_head",
    },
    {
      name: <Title columname={"BEGINING BALANCE"} columsubname={ColumnSub} />,
      selector: (row) => row.startingLeftLeg,
      cell: (row) => (
        <div className="table_reward_tabl">
          <span className="table_reward_span">{row.startingLeftLeg}</span>
          <span className="table_reward_span">{row.startingRightLeg}</span>
        </div>
      ),
      style: {
        padding: "0px",
      },
      width: "20%",
      id: "table_reward_head",
    },

    {
      name: (
        <Title columname={"TRANSACTION  BALANCE"} columsubname={ColumnSub} />
      ),
      selector: (row) => row.amount,
      cell: (row) => (
        <div className="table_reward_tabl">
          <span className="table_reward_span">{row.transactionLeft}</span>
          <span className="table_reward_span">{row.transactionRight}</span>
        </div>
      ),
      style: {
        padding: "0px",
      },
      width: "20%",
      id: "table_reward_head",
    },
    {
      name: <Title columname={"INFINITE REWARDS"} columsubname={ColumnSub} />,
      selector: (row) => row.endingLeft,
      cell: (row) => (
        <div className="table_reward_tabl">
          <span className="table_reward_span">{row.endingLeft}</span>
          <span className="table_reward_span">{row.endingRight}</span>
        </div>
      ),
      style: {
        padding: "0px",
      },
      width: "20%",
      id: "table_reward_head",
    },
    {
      name: <Title columname={"INFINITE REWARDS"} columsubname={""} />,
      selector: (row) => row.reward,
      cell: (row) => (
        <span className={`${row.classnameamount} ${style.badge}`}>
          {Currency.currentCurr}{" "}
          {CurrencyDecimalFormat(
            row.reward * Currency.value,
            Currency.precision
          )}
        </span>
      ),
      width: "20%",
      id: "table_reward_head",
    },
  ];

  const SubmitEvents = (start, end, type = "") => {
    SetDate((prev) => ({
      ...prev,
      start: start,
      end: end,
      selectedValues: type,

    }));
    let keys = [];
    type.map((key, idx) => {

      keys.push(key.id);
    });
    filterHandler("", "", start, end, keys);

  };

  const resetData = () => {
    filterHandler(
      1,
      10,
      moment().startOf("month").format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD"),
      ""
    );
  };

  const rowChangePerPage = (inPage, perPage) => {
    handlePerRowsChange(
      inPage,
      perPage,
      date.start,
      date.end,
      date.selectedValues
    );
  };
  const changePage = (inPage) => {
    let keys = [];
    date.selectedValues.map((key, idx) => {
      keys.push(key.value);
    });
    paginationChange(
      inPage,
      ContentData.perPage,
      date.start,
      date.end,
      "",
      "",
      keys
    );
  };
  console.log('datedd', date.expandedDate)

  return (
    <div className={style.summary}>
      <div
        className={`${currentTheme == "theme-dark"
            ? `${style.dark_mode_tabContent}`
            : `${style.tabContent}`
          }`}
      >
        <div className={style.dataTable} style={stylees}>
          <InfiniteFilter
            filterHandler={SubmitEvents}
            resetHandler={resetData}
            isSingle={false}
            exports={true}
            exportData={exportData}
            printData={printData}
            isMonth={false}
            StartDate={date.start}
            EndDate={date.end}
          />
          <div className={style.datatable}>
            <DataTable
              columns={columns}
              data={tableData}
              highlightOnHover
              progressComponent={<div>Loading</div>}
              persistTableHead
              noHeader
              pagination
              paginationServer
              responsive
              paginationTotalRows={rowsCount}
              onChangeRowsPerPage={rowChangePerPage}
              onChangePage={changePage}
              noDataComponent={t("Common.noDataRecordsToDisplay")}
              striped
              expandableRows={Title}
              expandableRowsComponent={Expand}
              onRowExpandToggled={(bool, row) =>
                SetDate({
                  ...date,
                  expandedDate: row.date,
                  userId:keys
                })
                
              }
              expandableRowsComponentProps={
                {
                  Date: date.expandedDate,
                  userid: date.userId
                }
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfiniteRewards;
