import React, { useState } from 'react';
import style from './history.module.scss';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Invoice from './Invoice';
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
const HistoryContent = ({ tableData,rowsCount,ContentData,paginationChange,rowsChange }) => {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const currency = useSelector(state => state.curr);
  const [state, setState] = useState({
    modalShow: false,
    modalData: {}
  })

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      //   width : "30%"
    },
    {
      name: t('Common.orderId'),
      selector: (row) => row.order_id,
      sortable: true,
      cell: (row) => <span className={`${style.badge}`}>{row.order_id}</span>,
    },
    {
      name: t('Common.memberName'),
      selector: (row) => row.user_name,
      sortable: true,
      cell: (row) => <span className={`${style.badge}`}>{row.user_name}</span>,
    },
    {
      name: t('Common.totalAmount'),
      selector: (row) => row.totalAmount,
      sortable: true,
      cell: (row) => <span className={`${style.badge}`}>{currency.currentCurr} {(parseFloat(row.total) * currency.value).toFixed(currency.precision)}</span>,
    },
    {
      name: t('payout.paymentMethod'),
      selector: (row) => row.payment_method,
      sortable: true,
      cell: (row) => <span className={`${style.badge}`}>{row.payment_method}</span>,
    },
    {
      name: t('Common.orderDate'),
      selector: (row) => row.orderDate,
      sortable: true,
      cell: (row) => <span className={`${style.badge}`}>{moment(row.date_added).format('MM-DD-YYYY')}</span>,
    },
    {
      name: t('Common.action'),
      cell: (row) => (
        <span className={style.actionIcon} onClick={() => handleShow(row)}>
          <i className="fa fa-eye"></i>
        </span>
      ),
    },
  ];

  const handleClose = () => {
    setState(prev => ({
      ...prev,
      modalShow: false,
      modalData: {}
    }))
  };
  const handleShow = (data) => {
    setState(prev => ({
      ...prev,
      modalShow: true,
      modalData: data
    }))
  };
  const changePage = (inPage) => {
   paginationChange(inPage,ContentData.perPage)
  }
 const  rowChangePerPage=(perPage,inpage,)=>{
  rowsChange(perPage,inpage)

  }
  return (
    <div className={`${currentTheme == "theme-dark"
        ? `${style.dark_mode_container}`
        : `${style.container}`
      }`}>
      <div className={`${style.datatable}`}>
        <DataTable
          columns={columns}
          data={tableData}
          highlightOnHover
          progressComponent={<div>Loading</div>}
          persistTableHead
          pagination
          paginationServer
          noHeader
          responsive
          onChangePage={changePage}
          paginationTotalRows={rowsCount}
          noDataComponent={t("Common.noDataRecordsToDisplay")}
          onChangeRowsPerPage={rowChangePerPage}
        />
      </div>
      {
        state.modalData &&
        <Invoice
          show={state.modalShow}
          handleClose={handleClose}
          data={state.modalData}
          currency={currency}
        />
      }
    </div>
  );
}

export default HistoryContent;