import React, { useEffect, useState } from "react";
import EwalletService from "../../../service/ewallet/Ewallet";
import Table from "react-bootstrap/Table";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//service
import AppInfoservice from "../../../service/common/Appinfo";

//style
import { Row, Col } from "react-bootstrap";
import ReportService from "../../../service/reports/reports";

export class ComponentToPrint extends React.PureComponent {
    constructor(props) {
        super(props);
        this.service = new EwalletService();

        const current = new Date();
        const date = `${current.getDate()}/${current.getMonth() + 1
            }/${current.getFullYear()} , ${current.getHours()}:${current.getMinutes()}`;
        // const time = current.getHours() + ':' + current.getMinutes();
        this.state = {
            earnings: [],
            loading: true,
            date: date,
        };
    }


    ConverLang(data) {
        let NewData = data.map((obj, key) => {
            if (obj.description1) {
                return {
                    ...obj,
                    description: this.props.t("ewallet." + obj.description1.langCode, {
                        username: obj.description1?.user,
                        type: obj.description1?.fee_type == 'fundtransfer' ? "( " + this.props.t("ewallet.transaction_fee") : "( " + this.props.t("ewallet.payout_fee"),
                        fee: obj.description1?.fee && "-" + parseFloat(obj.description1?.fee).toFixed(2) + " )",
                        Currency: obj.description1?.currency,
                        loadType: obj.description1?.loadType
                    }),
                }
            }
            else return obj

        })
        console.log("NewData", NewData)
        return NewData
    }


    exportDatas(type) {

        this.service.exportData()
            .then((res) => {
                console.log(res)
                if (res.status) {

                    this.setState((prevState) => ({
                        ...prevState,
                        loading: false,
                        earnings: res.data.table_data,
                    }));
                } else {
                    this.setState((prevState) => ({
                        ...prevState,
                        loading: true,
                    }));
                }
            });
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.data.FilterDate != prevProps.data.FilterDate
        ) {
            this.exportDatas("print");
        }
    }
    componentDidMount() {
        this.exportDatas("print");
    }

    render() {
        return (
            <div style={{ padding: 60 }}>
                <Row>
                    <Col>{this.state.date}</Col>
                    <Col>
                        <h5 style={{ textAlign: "center" }}>Statment Report</h5>
                    </Col>
                </Row>

                <div ref={this.ref}>
                    <Row style={{ marginBottom: 50 }}>
                        <Col span={8}>
                            {/* <img src={this.props.company.logo} alt="Logo" /> */}
                            <div>COMPANY NAME : {this.props.company.comapny_name}</div>
                            <div>COMPANY ADDRESS : {this.props.company.company_address}</div>
                            <div>TELEPHONE : {this.props.company.phone}</div>
                            <div>EMAIL : {this.props.company.email}</div>
                        </Col>
                    </Row>

                    {!this.state.loading && this.state.earnings.length > 0 && (
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th>{this.props.t("Common.description")}</th>
                                    <th>{this.props.t("Common.amount")}</th>
                                    <th>{this.props.t("Common.transactionDate")}</th>
                                    <th>{this.props.t("Common.balance")}</th>

                                </tr>
                            </thead>
                            <tbody>
                                {this.ConverLang(this.state.earnings).map((obj, index) => {


                                    return (
                                        <tr key={index}>
                                            <td>{obj.description}</td>
                                            <td>
                                                {" "}
                                                {this.props.Currency.currentCurr}
                                                {(
                                                    parseFloat(obj.amount) *
                                                    this.props.Currency.value
                                                ).toFixed(this.props.Currency.precision)}
                                            </td>
                                            <td>{obj.transaction_date}</td>
                                            <td>
                                                {" "}
                                                {this.props.Currency.currentCurr}
                                                {(
                                                    parseFloat(obj.balance) *
                                                    this.props.Currency.value
                                                ).toFixed(this.props.Currency.precision)}
                                            </td>

                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    )}
                </div>
            </div>
        );
    }
}

const StatementPrint = React.forwardRef((props, ref) => {
    const Currency = useSelector((state) => state.curr);
    const { t } = useTranslation();

    const [companyDetails, setCompanyDetails] = useState({});

    const service = new AppInfoservice();

    useEffect(() => {
        service.Appinfo().then((res) => {
            if (res.status) {
                setCompanyDetails((prevState) => ({
                    ...prevState,
                    logo: res.data.company_info.login_logo,
                    comapny_name: res.data.company_info.company_name,
                    company_address: res.data.company_info.company_address,
                    phone: res.data.company_info.phone,
                    email: res.data.company_info.email,
                }));
            }
        });
    }, []);
    return (
        <ComponentToPrint
            ref={ref}
            data={props}
            company={companyDetails}
            Currency={Currency}

            t={t}
        />
    );
});
export default StatementPrint;
