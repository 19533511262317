import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import Card from "react-bootstrap/Card";
//components
import PageTitle from "../../components/common/pageTitle";
import { useSelector } from "react-redux";
import PaymentEwallet from "./PaymentEwallet";
import Spinner from "../../shared/Spinner";

//service
import EwalletService from "../../../service/ewallet/Ewallet";
import ProfileService from "../../../service/profile";
import companyName from "../../../store/mobxStore/companyName";

import style from "../../components/registration/registration.module.scss";
import { AlertMessage } from "../../components/common";
import { Modal } from "react-bootstrap";
import qs from "qs";
import SafexpayModal from "../../components/Payments/SafexpayModal";
import modalstyle from "../../components/profile/profile.module.scss";
const mapStateToProps = (state) => ({ lang: state.Lang });

const Ewalletload = (props) => {
  const Currency = useSelector((state) => state.curr);
  const service = new ProfileService();
  const ewalservice = new EwalletService();

  const [state, setState] = useState({
    PaymentMethods: [],
    selectedPayment: "",
    safexpayData: {},
    amount: 0,
    userName: "",
    product: "",
    loader: true,
    btnloader: false,
    safexpayamount: 0,
    modalStatus: false,
    safexpaycontent: {},
    notify: {
      message: "",
      show: false,
      type: "",
    },
  });

  const { t } = props;
  const history = useHistory();

  useEffect(() => {
    loadPaymentData();
  }, []);

  const loadPaymentData = () => {
    service.getPaymentData("ewallet_load").then((res) => {
      if (res.status) {
        setState((prevstate) => ({
          ...prevstate,
          PaymentMethods: res.data,
          selectedPayment: res.data[0],
          loader: false,
        }));
      }
    });
  };
  const getSafexpay = (amnt) => {
    setState((prev) => ({
      ...prev,
      safexpayamount: amnt,
    }));
  };

  const submittheForm = (event) => {
    if (event.hasOwnProperty("defaultPrevented")) {
      event.preventDefault();
    }

    let formData = {};

    if (state.selectedPayment.code === "safexpay") {
      formData.payment_method = state.selectedPayment.code;
      formData.amount = state.safexpayamount;
      // const data = {
      //   me_id: '202303090001',
      //   merchant_request: 'mHHpjh5OH8NcCm2Z91CXAJWeq1H/MXnL5S9xOOoLK6KT6das56kGnngTwW8TGxCQ/Xq2LijO7tPLkyvBjNYjTcnqKgI3QJG87E0AF1pJABn+JZxM5oe21JgUaa4yq7KgtY1gQ47M2UqoywPGk1b9pszJ/Z4piz3cxcIXz0AINBYukG4fcvGJR4YeD5c4YG+U8uZDGW6CBx3NWZGNo7YIPh9N/YGWRe+LtFyEKQ+KGl84dehw3+UBFC7Mx1i8aTzmDsa6iSta8i0C1oiD2dukxA==',
      //   hash: 'RWQbfQbO8kjVLY9d/Z3IgUuP9qTENlawFqufJmb+cV3Gdqes0kFs6eJmIId23e3nudt8lU+b73pEXFWE3NpJwsotFpUD7oxg+R1eD7SGXWI='
      // }
      // const formdata=new FormData()
      // formdata.append(data)
      // const data = {
      //   me_id: '202303090001',
      //   merchant_request: 'mHHpjh5OH8NcCm2Z91CXAJ9EsFtdJpuSy530jvwMheEGzP+S2mYG9WWj6UsIxVzlZmUA7gKSA1tP/+HVBAaZ++Gi5QSHERKv8KBSsB80PSZoJgGE9Owcwg6fCVETG/PKWyvLcXAIq9lUFwfd2ePh+DorU9U0om5zGY0NjQW8JN8T65ICgB/NVxRRW66bBjzKXyDwPnGo4ql5keoizQIeH4oNwTWH+7V5MgcLxxn3bJhKk3+r6ssYbSgnl/LjTXz8ryB7yCSJdx9krjhQegVl/Q==',
      //   hash: '8YFr13ACwvW1ur12lX1Kl5+8K+Y/AudDLUUpiAa4P6m+gAU3mUj+KKUxSjRgbpbQKPni1YCbn4YElKcjV0c4Q4o37lwt8jRUCTQ2dk6T+NM='
      // }
      // const urlEncodedData = qs.stringify(data);
      // console.log("urlEncodedData",urlEncodedData)
      // const formdata = new FormData();
      // // Object.keys(data).forEach((key) => {
      // //   formdata.append(key, data[key]);
      // // });

      // fetch('https://pg.safexpay.ae/agcore/paymentProcessing', {
      //   method: 'POST',
      //   body: urlEncodedData,
      //   headers: {
      //     'Content-Type':'application/x-www-form-urlencoded',
      //     // 'Content-Type':'multipart/form-data',
      //     // 'Content-Type': 'application/json'
      //   }
      // }).then(async (response) => {
      //   console.log('responggghhh',response)
      //   const text = await response.text();
      //   console.log('responseeeejjj', text);

      //   setState((prev) => ({
      //     ...prev,
      //     // btnloader: true
      //     safexpaycontent: text,
      //     modalStatus: true
      //   }));
      // }).catch((error) => {
      //   console.error(error);
      // });
    }
    formData.payment_method = state.selectedPayment.code;
    ewalservice.ewalletloadsubmit(formData).then((res) => {
      if (res.status) {
        setState((prev) => ({
          ...prev,
          btnloader: false,
        }));
        // history.push("/ewallet");
        if (res.data.safexpay) {
          // console.log("entered===========",res.data.return_elements)
          const data = res.data.return_elements;
          if (data) {
            console.log("data", data);
            window.Buy(data);
          }
          setState((prev) => ({
            ...prev,
            safexpaycontent: data,
            // modalStatus:true
          }));
          // console.log("safxpay dataa",data)
          // const safexpayData=new FormData()
          //  Object.keys(data).forEach((key) => {
          //   safexpayData.append(key, data[key]);
          // });
          const urlEncodedData = qs.stringify(data);

          // https://pguat.safexpay.ae/agcore/paymentProcessing'
          fetch("https://pg.safexpay.ae/agcore/paymentProcessing", {
            method: "POST",
            body: urlEncodedData,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              // 'Content-Type':'multipart/form-data',
              // 'Content-Type': 'application/json'
            },
          })
            .then(async (response) => {
              const text = await response.text();
              console.log("texttttt", text);
              // setState((prev) => ({
              //   ...prev,
              //   // btnloader: true
              //   safexpaycontent: text,
              //   modalStatus: true
              // }));
            })
            .catch((error) => {
              console.log("Error paymentProcessing api safexpay", error);
              console.error(error);
            });
        } else {
          history.push("/ewallet");
        }
      } else if (res.error.code === 1030) {
        setState((prev) => ({
          ...prev,
          btnloader: false,
          notify: {
            show: true,
            type: "danger",
            message: t(`validation.${res.error.description}`),
          },
        }));
      }
    });
  };

  const paymentmethodchangehere = (tab) => {
    setState((prevState) => ({
      ...prevState,
      selectedPayment: state.PaymentMethods[tab],
    }));
  };
  const dismisshandler = () => {
    setState((prev) => ({
      ...prev,
      notify: {
        ...prev.notify,
        show: false,
        message: "",
        type: "",
      },
    }));
  };

  const handleClose = () => {
    setState((prev) => ({
      ...prev,
      modalStatus: false,
    }));
  };
  return (
    <div className={`h-100`}>
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.ewalload")}
        </title>
      </Helmet>
      <PageTitle title="ewalload" buttonOn={false} />

      {state.loader ? (
        <Spinner />
      ) : (
        <div className={style.MainContainer}>
          {state.notify.show && (
            <AlertMessage
              message={state.notify.message}
              dismiss={dismisshandler}
              type={state.notify.type}
              show={state.notify.show}
            />
          )}
          {state.modalStatus && (
            <Modal
              show={state.modalStatus}
              onHide={handleClose}
              contentClassName={style.ModalContent}
            >
              <Modal.Header
                closeButton
                className={style.ModalHeader}
              ></Modal.Header>
              <Modal.Body className={style.ModalBody}>
                <SafexpayModal safexpayModalcontent={state.safexpaycontent} />
                {/* <iframe
                    style={{ border: 'none' }}
                    srcDoc={state.safexpaycontent}
                    title="My HTML Page"
                    width="100%"
                    height="500"
                  ></iframe> */}

                {/* <div dangerouslySetInnerHTML={{__html:state.safexpaycontent}}></div> */}
              </Modal.Body>
            </Modal>
          )}
          {/* <Card>
            <Card.Body className="paymentCARD"> */}
          <PaymentEwallet
            // productAmount={state.amount}
            data={state.PaymentMethods}
            // prevButtonClick={prevPage}
            submit={submittheForm}
            paymentMethodChange={paymentmethodchangehere}
            // userName={state.userName}
            // product={state.product}
            // ewallet={getEwalletData}
            // epin={getEpinData}
            // getPaypalData={paypalData}
            payment_type="ewallet_load"
            // loader={loading}
            loader={state.btnloader}
            getSafexpay={getSafexpay}
          />
          {/* </Card.Body>
          </Card> */}
        </div>
      )}
    </div>
  );
};

export default withTranslation()(
  connect(mapStateToProps)(withRouter(Ewalletload))
);
