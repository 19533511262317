import companyName from "../store/mobxStore/companyName";

//live
const COMPANY_NAME = companyName.companyName ?? "Company Name";
const BASE_URL = "https://admin.8-rewards.net/api/web/";
const IMG_URL = "https://admin.8-rewards.net/uploads/images/";
const BASE = "https://admin.8-rewards.net/";
const API_KEY = "1055"; //binaryaddon;
const DEFAULT_KEY = "387245a5a919ea2071cc406b10b89d4685e5cc8e";

//demo
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://192.168.9.40:5001/api/web/";
// const IMG_URL = "http://162.19.146.132:1048/uploads/images/";
// const BASE = "http://162.19.146.132:1048/";
// const API_KEY ='1055'; //binaryaddon;
// const DEFAULT_KEY='387245a5a919ea2071cc406b10b89d4685e5cc8e'

// local
// const COMPANY_NAME = companyName.companyName ?? "Company Name";
// const BASE_URL = "http://192.168.21.19:5008/api/web/";
// const IMG_URL = "http://192.168.21.19:5008/uploads/images/";
// const BASE = "http://192.168.21.19:5008/";
// const API_KEY = "1055"; //binaryaddon;
// const DEFAULT_KEY = "387245a5a919ea2071cc406b10b89d4685e5cc8e";

export { COMPANY_NAME, BASE_URL, IMG_URL, API_KEY, BASE, DEFAULT_KEY };
