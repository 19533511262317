import React, { useState, useEffect } from "react";
import PageTitle from "../../components/common/pageTitle";
import style from "../../components/Reports/report.module.scss";
import { Helmet } from "react-helmet";
import companyName from "../../../store/mobxStore/companyName";
import { useTranslation } from "react-i18next";




export const PvConfig = () => {
    const { t } = useTranslation()


    return (
        <div className={`h-100`}>
            <Helmet>
                <title>
                    {companyName.companyName} | {t("Sidemenu.pvConfigurarton")}
                </title>
            </Helmet>
            <PageTitle title="pvConfigurarton" buttonOn={false} />
            <div className={style.MainContainer}>
            </div>
        </div>
    )

}
