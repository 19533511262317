import React, { useEffect, useState, useRef } from "react";
// import {Tabs,Tab} from 'react-bootstrap';
import { useTranslation } from "react-i18next";
//compoents
import Tabs from "react-responsive-tabs";
import style from "./reward.module.scss";
import { propTypes } from "react-bootstrap/esm/Image";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import Filter from "./Filter";
import { CurrencyDecimalFormat } from "../../helper";
import moment from "moment";
import ConsumerPrintView from "./ConsumerPrintView";

const stylees = {
  paddingTop: "10px",
  fontFamily: "system-ui",
};

function ConsumerRewards({ tableData, filterHandler, rowsCount, handlePerRowsChange, paginationChange, ContentData, exportData, printData }) {

  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const componentRef = useRef();
  const [tabs, setTabs] = useState(["individualReports", "salesReports"]);
  const [data, setData] = useState({
    table: [],
    multiSelect: [],

  });
  const Currency = useSelector((state) => state.curr);
  const [date, SetDate] = useState({
    start: moment().startOf("month").format("MM-YYYY"),
    end: moment().format("MM-YYYY"),
    selectedValues: [],
  });

  const columns = [
    {
      name: t("Common.id").toUpperCase(),
      selector: (row) => row.id,
      // width: "25%",
    },
    
    {
      name: t("Common.Name").toUpperCase(),
      selector: (row) => row.Name,
      width: "25%",
    },

    {
      name: t("Common.TotalInfinite").toUpperCase(),
      cell: (row) => (
        <span className={`${row.classnameamount} ${style.badge}`}>
          {Currency.currentCurr}{" "}
          {CurrencyDecimalFormat(row.infinitereward * Currency.value, Currency.precision)}
        </span>
      ),
      width: "20%",
    },
   
    {
      name: t("Common.consumerCare").toUpperCase(),
      selector: (row) => row.ConsumerCareRewards,
      cell: (row) => (
        <span className={`${row.classnameamount} ${style.badge}`}>
          {Currency.currentCurr}{" "}
          {CurrencyDecimalFormat(row.ConsumerCareRewards * Currency.value, Currency.precision)}
        </span>
      ),
      width:"20%"
    },
    {
      name: t("Common.date").toUpperCase(),
      selector: (row) => row.Date,
     
    },
    
  ];


  useEffect(() => {
    let newFilter = tableData.map((obj) => {
      console.log('tabledataa',obj)
      if (obj.type) {
        return {
          name: obj.type,
          id: obj.type
        }
      }
    })

    setData((prev) => ({
      ...prev,
      table: tableData,
      multiSelect: newFilter
    }))
  }, [tableData]);

  const SubmitEvents = (start, end, month) => {

    SetDate((prev) => ({
      ...prev,
      // start: moment(month).format("MM-YYYY"),
      start:start,
      end: end,
    }));
    // filterHandler("", "", moment(month).format("MM-YYYY"));
    filterHandler("", "", start,end);
  };
  const resetData = () => {
    filterHandler("", "", moment().startOf("month").format("MM-YYYY"),moment().format("YYYY-MM-DD"));
  }

  const rowChangePerPage = (inPage, perPage) => {
    handlePerRowsChange(inPage, perPage, date.start,date.end)
  }
  const changePage = (inPage) => {
    paginationChange(inPage,
      ContentData.perPage, date.start,date.end)
  }
  const removeDuplicates = (data) => {
    const ids = data.map(o => o.id)
    const filtered = data.filter(({ id }, index) => !ids.includes(id, index + 1))
    return filtered
  }
  const getTranslation = (data) => {
    let newData = data.map((obj) => {
      if (obj.type) {
        return {
          ...obj,
          type: t(`Common.${obj.type}`)

        }
      }
      else return obj
    })
    return newData
  }

  return (
    <div className={style.summary}>
      <div
        className={`${currentTheme == "theme-dark"
          ? `${style.dark_mode_tabContent}`
          : `${style.tabContent}`
          }`}
      >
        <div className={style.dataTable} style={stylees}>

          <Filter
            filterHandler={SubmitEvents}
            //type={removeDuplicates(data.multiSelect)}
            resetHandler={resetData}
            isSingle={false}
            exports={true}
            exportData={exportData}
            printData={printData}
            isMonth={true}
            FilterDate={date.start}
            FileName={'Consumer Care Rewards'}
            componentToPassDown={<ConsumerPrintView/>}
          />
          <DataTable
            columns={columns}
            data={getTranslation(data.table)}
            highlightOnHover
            // progressPending={ContentData.pending}
            progressComponent={<div>Loading</div>}
            persistTableHead
            noHeader
            pagination
            paginationServer
            responsive
            paginationTotalRows={rowsCount}
            onChangeRowsPerPage={rowChangePerPage}
            onChangePage={changePage}
            noDataComponent={t("Common.noDataRecordsToDisplay")}
          //onSort={handleOnSort}
          />
        </div>
      </div>
    </div>
  );
}

export default ConsumerRewards;
