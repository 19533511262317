import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import style from "../style/App.module.scss";
import { Language } from "../components/common/";
import Brand from "../components/auth/Brand";
import { useSelector } from "react-redux";
import profileImg from "../../assets/images/auth/profile-img.png";
function AuthLayout({ children }) {
  const { currentTheme } = useSelector((state) => state.Theme);
  useEffect(() => {
    if (currentTheme !== "theme-dark") {
      document.body.style.setProperty("background", "#f8f8fb", "important");
    }
    // document.body.style.setProperty("p", "12px", "important");
    return () => {
      document.body.style.removeProperty("background");
    };
  }, []);
  return (
    <div
      className={`${
        currentTheme == "theme-dark"
          ? `${style.dark_mode_app} pt-5`
          : `${style.app} pt-5`
      }`}
    >
      <Container
        className={`${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_loginContainer}`
            : `${style.loginContainer}`
        }`}
      >
        <div className="row justify-content-center">
          <Brand />
          <div
            className={`${
              currentTheme == "theme-dark"
                ? `${style.dark_mode_style_bgPrimary}`
                : `${style.bgPrimary}`
            }`}
          >
            <div className="row">
              <div className="col-7">
                <div className="text-primary p-4">
                  <h5 className="text-primary">Welcome Back !</h5>
                  <p
                    ref={(el) => {
                      if (el) {
                        el.style.setProperty("fontSize", "12px", "important");
                      }
                    }}
                    style={
                      currentTheme == "theme-dark"
                        ? { color: "white", fontSize: "12px !important" }
                        : { color: "#5870e6", fontSize: "12px !important" }
                    }
                  >
                    Sign in to continue
                  </p>
                </div>
              </div>
              <div className="col-5 align-self-end">
                <img src={profileImg} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
        <div className={`${style.bgLogin}`}>{children}</div>
        {/* <Brand /> */}
        {/* <Language classname="mb-4 w-100" /> */}
        {/* <div class="bg-primary bg-soft">
        <div class="row">
         <div class="col-7">
         <div class="text-primary p-4">
          <h5 class="text-primary">Welcome Back !</h5>
          <p>Sign in to continue</p>
           </div>
                                </div>
                                <div class="col-5 align-self-end">
                                    <img src="assets/images/profile-img.png" alt="" class="img-fluid"/>
                                </div>
                            </div>
                        </div> */}
      </Container>
      <div
        className={`${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_lg_login_btn_btm_1}`
            : `${style.lg_login_btn_btm_1}`
        }`}
      >

      </div>
    </div>
  );
}

export default AuthLayout;
