import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../components/common/pageTitle';
import Spinner from '../../shared/Spinner';
import HistoryContent from '../../components/orderHistory/historyContent';
import OrderService from '../../../service/orderHistory/orderHistory';
import companyName from '../../../store/mobxStore/companyName';
import { useHistory, withRouter } from 'react-router';


function OrderHistory({ t }) {
    let service = new OrderService()
    let history = useHistory()
    const [state, setState] = useState({
        loading: true,
        DataTables: {
            totalRows: 0,
            data: [],
            pending: true,
            perPage: 10,
            inPage: 1,
        },
    })

    useEffect(() => {
        getHistory();

    }, []);


    const getHistory = (
        inPage = state.DataTables.inPage,
        perPage = state.DataTables.perPage,
    ) => {
        let start = (inPage - 1) * perPage;
        service.getOrderHistory(start,perPage).then(res => {
            if (res.status) {
                setState({
                    loading: false,
                    DataTables: {
                        data: res.data.order_details,
                        totalRows: res.data.count,
                        pending: true,
                        perPage: 10,
                        inPage: 1,
                    }
                })
            }
            else {
                if (res.error.code === 1002) {
                    history.push('/logout');
                } else if (res.error.code === 1057) {
                    history.push({
                        pathname: '/dashboard',
                        state: {
                            error: true,
                            message: 'permissionDenied'
                        }
                    });
                }
            }
        })
    }

    const paginationChange=(inpage,perPage)=>{
        setState({
            ...state,
            DataTables: {
                pending: true,
                perPage: perPage,
                inPage: inpage,
            }
        })
        getHistory(perPage,inpage);
    }
    const rowsChange=(perPage,inpage)=>{
        setState({
            ...state,
            DataTables: {
                pending: true,
                perPage: perPage,
                inPage: inpage,
            }
        })
        getHistory(inpage,perPage);
    }


    if (state.loading) {
        return <Spinner />
    }
    return (
        <React.Suspense fallback={<Spinner />}>
            <Helmet>
                <title>{companyName.companyName} | {t('Sidemenu.orderHistory')}</title>
            </Helmet>
            <PageTitle title="orderHistory" buttonOn={false} />
            <HistoryContent
                tableData={state.DataTables.data}
                rowsCount={state.DataTables.totalRows}
                ContentData={state.DataTables}
                paginationChange={paginationChange}
                rowsChange={rowsChange}
                {...state}
            />
        </React.Suspense>
    )
}
export default withTranslation()(withRouter(OrderHistory))
