import React, { useEffect, useState, useRef } from "react";
// import {Tabs,Tab} from 'react-bootstrap';
import { useTranslation } from "react-i18next";
//compoents
import Tabs from "react-responsive-tabs";
import style from "./reward.module.scss";
import { propTypes } from "react-bootstrap/esm/Image";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import Filter from "./Filter";
import { CurrencyDecimalFormat } from "../../helper";
import moment from "moment";
import ConsumerPrintView from "./ConsumerPrintView";
import DateFilter from "./DateFilter";

const stylees = {
  paddingTop: "10px",
  fontFamily: "system-ui",
};

function CashbackRewards({
  tableData,
  filterHandler,
  rowsCount,
  handlePerRowsChange,
  paginationChange,
  ContentData,
  exportData,
  printData,
}) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const componentRef = useRef();
  const [tabs, setTabs] = useState(["individualReports", "salesReports"]);
  const [data, setData] = useState({
    table: [],
    multiSelect: [],
  });
  console.log("printData", printData);
  const Currency = useSelector((state) => state.curr);
  const [date, SetDate] = useState({
    start: moment().startOf("month").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
    selectedValues: [],
  });

  const columns = [
    {
      name: t("Common.Date").toUpperCase(),
      selector: (row) => row.date,
      width: "15%",
    },
    {
      name: t("Common.TransNo").toUpperCase(),
      selector: (row) => row.id,
      width: "15%",
    },
    {
      name: t("Common.amount").toUpperCase(),
      selector: (row) => row.amount,
      cell: (row) => (
        <span className={`${row.classnameamount} ${style.badge}`}>
          {Currency.currentCurr}{" "}
          {CurrencyDecimalFormat(
            row.amount * Currency.value,
            Currency.precision
          )}
        </span>
      ),
      width: "15%",
    },
    {
      name: t("Common.PointValue").toUpperCase(),
      selector: (row) => row.pair_value,
      width: "15%",
    },
    {
      name: t("Common.CashBack").toUpperCase(),
      cell: (row) => (
        <span className={`${row.classnameamount} ${style.badge}`}>
          {Currency.currentCurr}{" "}
          {CurrencyDecimalFormat(
            row.cashback * Currency.value,
            Currency.precision
          )}
        </span>
      ),
      width: "15%",
    },
  ];

  useEffect(() => {
    let newFilter = tableData.map((obj) => {
      if (obj.type) {
        return {
          name: obj.type,
          id: obj.type,
        };
      }
    });

    setData((prev) => ({
      ...prev,
      table: tableData,
      multiSelect: newFilter,
    }));
  }, [tableData]);

  const SubmitEvents = (start, end, type = "") => {
    SetDate((prev) => ({
      ...prev,
      start: start,
      end: end,
      selectedValues: type,
    }));
    let keys = [];
    type.map((key, idx) => {
      keys.push(key.id);
    });
    filterHandler("", "", start, end, keys);
  };
  const resetData = () => {
    filterHandler(
      1,
      10,
      moment().startOf("month").format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD"),
      ""
    );
  };

  const rowChangePerPage = (inPage, perPage) => {
    handlePerRowsChange(
      inPage,
      perPage,
      date.start,
      date.end,
      date.selectedValues
    );
  };
  const changePage = (inPage) => {
    console.log("change", inPage, ContentData.perPage);
    let keys = [];
    date.selectedValues.map((key, idx) => {
      keys.push(key.value);
    });
    paginationChange(
      inPage,
      ContentData.perPage,
      date.start,
      date.end,
      "",
      "",
      keys
    );
  };
  const removeDuplicates = (data) => {
    const ids = data.map((o) => o.id);
    const filtered = data.filter(
      ({ id }, index) => !ids.includes(id, index + 1)
    );
    return filtered;
  };
  const getTranslation = (data) => {
    let newData = data.map((obj) => {
      if (obj.type) {
        return {
          ...obj,
          type: t(`Common.${obj.type}`),
        };
      } else return obj;
    });
    return newData;
  };
  return (
    <div className={style.summary}>
      <div
        className={`${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_tabContent}`
            : `${style.tabContent}`
        }`}
      >
        <div className={style.dataTable} style={stylees}>
          <DateFilter
            filterHandler={SubmitEvents}
            type={removeDuplicates(data.multiSelect)}
            resetHandler={resetData}
            isSingle={false}
            exports={true}
            exportData={exportData}
            printData={printData}
          />
          <DataTable
            columns={columns}
            data={getTranslation(data.table)}
            highlightOnHover
            // progressPending={ContentData.pending}
            progressComponent={<div>Loading</div>}
            persistTableHead
            noHeader
            pagination
            paginationServer
            responsive
            paginationTotalRows={rowsCount}
            onChangeRowsPerPage={rowChangePerPage}
            onChangePage={changePage}
            noDataComponent={t("Common.noDataRecordsToDisplay")}
            //onSort={handleOnSort}
          />
        </div>
      </div>
    </div>
  );
}

export default CashbackRewards;
