import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import companyName from '../../../store/mobxStore/companyName';
import { Card } from 'react-bootstrap'
import style from '../../components/SafexpayMsg/success.module.scss'
import fail_img from '../../../assets/images/payment_fail_icon.png'
function Failed(props) {
    console.log("propssss", props.history.location)
    const { t } = useTranslation();
    const msg = props?.history?.location?.state?.message
    
    return (
        <div>
            <Helmet>
                <title>{companyName.companyName} | {t('Sidemenu.paymentfailed')}</title>
            </Helmet>

            <div className='container'>
                <center>
                    <div className={style.success}>

                        <Card style={{ width: '50%', padding: '5em' }}>
                            <Card.Body>

                                <img src={fail_img} alt="noimage" style={{ width: '70px' }} />
                                <p>{t('Common.paymentfailed')}</p><br/>
                                {/* {
                                    msg ?
                                        <small>{msg}</small> : <small>{t('Common.paymentfailed')}</small>
                                } */}
                                

                            </Card.Body>
                        </Card>

                    </div>
                </center>

            </div>
        </div>
    )
}

export default Failed
