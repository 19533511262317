import React, { useState, useEffect, useRef } from "react";
import style from "../../components/Reports/report.module.scss";
import { Helmet } from "react-helmet";
import { PageTitle } from "../../components/common";
import companyName from "../../../store/mobxStore/companyName";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useHistory } from "react-router";
import Spinner from "../../shared/Spinner";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
import { LoadReport } from "../../components/EwalletReports";
import ReportService from "../../../service/reports/reports";


export const Load = () => {
  //states 
  const { t } = useTranslation();
  const Currency = useSelector((state) => state.curr);
  const history = useHistory();
  const [state, setState] = useState({
    loading: true,
    DataTables: {
      totalRows: 0,
      data: [],
      pending: true,
      perPage: 10,
      inPage: 1,
      startDate: moment().startOf("month").format("MMMM D, YYYY"),
      endDate: moment().format("MMMM D, YYYY"),
    },
    tilesData: [],
    printData: []
  });

  //services
  const service = new ReportService();

  //functions 
  useEffect(() => {
    getData();
  }, [])
  const getData = (inPage = state.DataTables.inPage,
    perPage = state.DataTables.perPage,
    startDate = state.DataTables.startDate,
    endDate = state.DataTables.endDate) => {
    let start = (inPage - 1) * perPage;
    service.getPurchaseReport(start, perPage, startDate, endDate).then((res) => {
      if (res.status) {
        setState({
          ...state,
          loading: false,
          DataTables: {
            ...state.DataTables,
            data: res.data.order_details,
            totalRows: res.data.count,
            perPage: 10,
            inPage: 1,
            pending: true,
          }
        })
      }
      else {
        history.push('/logout')
      }
    })
  }
  const handlePerRowsChange = (
    inPage,
    perPage,
    start = "",
    end = "",
  ) => {
    setState((prevState) => ({
      ...prevState,
      DataTables: {
        ...prevState.DataTables,
        perPage: perPage,
        inPage: inPage,
        pending: true,
        startDate: start,
        endDate: end,
      },
    }));
    getData(
      perPage,
      inPage,

      start,
      end
    );
  };
  const paginationChange = (
    inPage,
    perPage,
    start = "",
    end = "",
  ) => {
    setState((prevState) => ({
      ...prevState,
      DataTables: {
        ...prevState.DataTables,
        perPage: perPage,
        inPage: inPage,
        pending: true,
        startDate: start,
        endDate: end,
      },
    }));
    getData(inPage, perPage, start, end);
  };
  const filterHandler = (inPage, perPage, start = "", end = "") => {
    setState((prevState) => ({
      ...prevState,
      DataTables: {
        ...prevState.DataTables,
        perPage: 10,
        inPage: 1,
        pending: true,
        startDate: start,
        endDate: end,
      },
    }));
    getData(
      state.DataTables.inPage,
      state.DataTables.perPage,
      start,
      end,
    );
  };
  const resetData = (start, end) => {

    // paginationChange(
    //   state.DataTables.inPage,
    //   state.DataTables.perPage,
    //   moment().startOf("month").format("MMMM D, YYYY"),
    //   "",
    //   "",
    //   ""
    // );
    getData();
  };

  const exportData = (type, fileName) => {
    service.exportPurchaseReport("", "", "", "").then((res) => {
      let fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let fileExtension = ".xlsx";
      if (type === "excel") {
        const ws = XLSX.utils.json_to_sheet(addCurrency(res.data.result));
        XLSX.utils.sheet_add_aoa(ws, [
          [

            "Order Id",
            "Date",
            "Member Name",
            "Total",
            "Payment Method",
            "Quantity",
           

          ],
        ]);

        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
      }
      if (type === "csv") {
        fileExtension = ".csv";
        let fileType = "text/csv;charset=utf-8";
        const ws = XLSX.utils.json_to_sheet(addCurrency(res.data.result));
        XLSX.utils.sheet_add_aoa(ws, [
          [
            "Order Id",
            "Date",
            "Member Name",
            "Total",
            "Payment Method",
            "Quantity",
          ],
        ]);
        const csvOutput = XLSX.utils.sheet_to_csv(ws);
        const data = new Blob([csvOutput], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
      }
    });
  };

  const addCurrency = (data) => {
    let NewData = data.map((obj) => {

      if (obj) {
        return {
          ...obj,
          total: Currency.currentCurr + (obj.total * Currency.value).toFixed(
            Currency.precision
          )
        }
      }
      else return obj
    })
    return NewData;


  }


  if (state.loading) {
    return (
      <Spinner />
    )
  }
  return (
    <div className={`h-100`}>
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.purchaseReport")}
        </title>
      </Helmet>
      <PageTitle title="purchaseReport" buttonOn={false} />
      <div className={style.MainContainer} style={style} >
        <LoadReport  {...state} handlePerRowsChange={handlePerRowsChange} paginationChange={paginationChange} filterHandler={filterHandler} resetData={resetData} exportData={exportData} />
      </div>
    </div>

  )
}
