import React, { useEffect, useState, useRef } from "react";
// import {Tabs,Tab} from 'react-bootstrap';
import { useTranslation } from "react-i18next";
//compoents
import Tabs from "react-responsive-tabs";
import style from "./report.module.scss";
import { propTypes } from "react-bootstrap/esm/Image";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import Filter from "./Filter";
import { CurrencyDecimalFormat } from "../../helper";

const stylees = {
  paddingTop: "10px",
  fontFamily: "system-ui",
};

function SummaryReport({ tableData, rowsCount }) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const Currency = useSelector((state) => state.curr);
  const { t } = useTranslation();
  const columns = [
    {
      // name: t("Common.id").toUpperCase(),
      selector: (row) => row.username,
      width: "30%",
    },
    {
      name: t("Common.cashback").toUpperCase(),
      selector: (row) => row.cashback,
      cell: (row) => (
        <span className={`${row.classnameamount} ${style.badge}`}>
          {Currency.currentCurr}{" "}
          {CurrencyDecimalFormat(
            row.cashback * Currency.value,
            Currency.precision
          )}
        </span>
      ),
    },
    {
      name: t("Common.infinite_rewards").toUpperCase(),
      selector: (row) => row.infinite_rewards,
      cell: (row) => (
        <span className={`${row.classnameamount} ${style.badge}`}>
          {Currency.currentCurr}{" "}
          {CurrencyDecimalFormat(
            row.infinite_rewards * Currency.value,
            Currency.precision
          )}
        </span>
      ),
    },
    {
      name: t("Common.consumer_care_rewards").toUpperCase(),
      selector: (row) => row.consumer_care_rewards,
      cell: (row) => (
        <span className={`${row.classnameamount} ${style.badge}`}>
          {Currency.currentCurr}{" "}
          {CurrencyDecimalFormat(
            row.consumer_care_rewards * Currency.value,
            Currency.precision
          )}
        </span>
      ),
    },
  ];

  return (
    <div className={style.summary}>
      <div
        className={`${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_tabContent}`
            : `${style.tabContent}`
        }`}
      >
        <DataTable
          columns={columns}
          data={tableData.data}
          highlightOnHover
          // progressPending={ContentData.pending}
          progressComponent={<div>Loading</div>}
          persistTableHead
          noHeader
          pagination
          paginationServer
          responsive
          paginationTotalRows={rowsCount}
          // onChangeRowsPerPage={rowChangePerPage}
          // onChangePage={changePage}
          noDataComponent={t("Common.noDataRecordsToDisplay")}
        />
      </div>
    </div>
  );
}

export default SummaryReport;
