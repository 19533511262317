import React, { useEffect, useState, useRef } from "react";
// import {Tabs,Tab} from 'react-bootstrap';
import { useTranslation } from "react-i18next";
//compoents
import Tabs from "react-responsive-tabs";
import style from "../../components/Reports/report.module.scss";
import { propTypes } from "react-bootstrap/esm/Image";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import { CurrencyDecimalFormat } from "../../helper";
import moment from "moment";
import DateFilter from "./DateFilter";
import Filter from "./Filter";

const stylees = {
  paddingTop: "10px",
  fontFamily: "system-ui",
};

export const StatmentReport = ({
  handlePerRowsChange,
  paginationChange,
  filterHandler,
  resetData,
  exportData,
  totalRows,
  DataTables,
  printData,
}) => {
  const { currentTheme } = useSelector((state) => state.Theme);
  const Currency = useSelector((state) => state.curr);
  const { t } = useTranslation();

  const [state, setState] = useState({ data: [] });
  const [date, SetDate] = useState({
    start: moment().startOf("month").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
    selectedValues: [],
  });

  useEffect(() => {
    let Data =
      DataTables.data &&
      DataTables.data.map((resData) => {
        if (resData.type === "credit") {
          return {
            classnameamount: style.credit,
            amount: resData.amount,
            balance: resData.balance,
            description: t("ewallet." + resData.description1.langCode, {
              username: resData.description1?.user,
              type:
                resData.description1?.fee_type == "fundtransfer"
                  ? "( " + t("ewallet.transaction_fee")
                  : "( " + t("ewallet.payout_fee"),
              fee:
                resData.description1?.fee &&
                "-" + parseFloat(resData.description1?.fee).toFixed(2) + " )",
              Currency: resData.description1?.currency,
              loadType: resData.description1?.loadType,
            }),
            transaction_date: resData.transaction_date,
          };
        } else if (resData.type === "debit") {
          return {
            classnameamount: style.debit,
            amount: resData.amount,
            balance: resData.balance,
            description: t("ewallet." + resData.description1.langCode, {
              username: resData.description1?.user,
              type:
                resData.description1?.fee_type == "fundtransfer"
                  ? "( " + t("ewallet.transaction_fee")
                  : "( " + t("ewallet.payout_fee"),
              fee: parseFloat(resData.description1?.fee).toFixed(2) + " )",
              Currency: "-" + resData.description1?.currency,
              loadType: resData.description1?.loadType,
            }),
            transaction_date: resData.transaction_date,
          };
        }
        return null;
      });
    setState((prev) => ({
      ...prev,
      data: Data,
    }));
  }, [DataTables.data, setState]);

  const SubmitEvents = (start, end, type = "") => {
    SetDate((prev) => ({
      ...prev,
      start: start,
      end: end,
      selectedValues: type,
    }));
    filterHandler("", "", start, end);
  };

  const columns = [
    {
      name: t("Common.description").toUpperCase(),
      selector: (row) => row.description,
      width: "30%",
      cell: (row) => (
        <span
          dangerouslySetInnerHTML={{ __html: row.description }}
          className={style.description}
        ></span>
      ),
    },
    {
      name: t("Common.amount").toUpperCase(),
      selector: (row) => row.amount,

      cell: (row) => (
        <span className={`${row.classnameamount} ${style.badge}`}>
          {Currency.currentCurr}{" "}
          {CurrencyDecimalFormat(
            row.amount * Currency.value,
            Currency.precision
          )}
        </span>
      ),
    },
    {
      name: t("Common.transactionDate").toUpperCase(),
      selector: (row) => row.transaction_date,
    },
    {
      name: t("Common.balance").toUpperCase(),
      selector: (row) => row.balance,
      cell: (row) => (
        <span className={`${style.balanceAmount} ${style.badge}`}>
          {Currency.currentCurr}{" "}
          {(row.balance * Currency.value).toFixed(Currency.precision)}
        </span>
      ),
    },
  ];

  const handlePageChanges = (inPage) => {
    paginationChange(inPage, DataTables.perPage);
  };
  const handlePerRowsChanges = (perPage, inPage) => {
    paginationChange(inPage, perPage);
  };

  return (
    <div className={style.summary}>
      <div
        className={`${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_tabContent}`
            : `${style.tabContent}`
        }`}
      >
        <div className={style.dataTable} style={stylees}>
          <Filter
            filterHandler={SubmitEvents}
            resetHandler={resetData}
            isSingle={false}
            exports={true}
            exportData={exportData}
            printData={printData}
          />
          <DataTable
            columns={columns}
            data={state.data}
            highlightOnHover
            // progressPending={ContentData.pending}
            progressComponent={<div>Loading</div>}
            persistTableHead
            noHeader
            pagination
            paginationServer
            responsive
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChanges}
            onChangePage={handlePageChanges}
            noDataComponent={t("Common.noDataRecordsToDisplay")}
            //onSort={handleOnSort}
          />
        </div>
      </div>
    </div>
  );
};
