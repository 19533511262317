import React, { useState, useEffect, useRef } from "react";
import style from "../../components/Reports/report.module.scss";
import { Helmet } from "react-helmet";
import { PageTitle } from "../../components/common";
import companyName from "../../../store/mobxStore/companyName";
import { useTranslation } from "react-i18next";
import { StatmentReport } from "../../components/EwalletReports";
import moment from "moment";
import EwalletService from "../../../service/ewallet/Ewallet";
import { useHistory } from "react-router";
import Spinner from "../../shared/Spinner";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";

export const Statement = () => {
  //states
  const { t } = useTranslation();
  const Currency = useSelector((state) => state.curr);
  const history = useHistory();
  const [state, setState] = useState({
    loading: true,
    DataTables: {
      totalRows: 0,
      data: [],
      pending: true,
      perPage: 10,
      inPage: 1,
      startDate: moment().startOf("month").format("MMMM D, YYYY"),
      endDate: moment().format("MMMM D, YYYY"),
    },
    tilesData: [],
    printData: [],
  });

  //services
  const service = new EwalletService();

  //functions
  useEffect(() => {
    getData();
  }, []);
  const getData = (
    inPage = state.DataTables.inPage,
    perPage = state.DataTables.perPage,
    startDate = state.DataTables.startDate,
    endDate = state.DataTables.endDate
  ) => {
    let start = (inPage - 1) * perPage;
    service.statement(start, perPage, startDate, endDate).then((res) => {
      if (res.status) {
        setState({
          ...state,
          loading: false,
          DataTables: {
            ...state.DataTables,
            data: res.data.table_data,
            totalRows: res.data.count,
            perPage: 10,
            inPage: 1,
            pending: true,
          },
        });
      } else {
        history.push("/logout");
      }
    });
  };
  const handlePerRowsChange = (inPage, perPage, start = "", end = "") => {
    setState((prevState) => ({
      ...prevState,
      DataTables: {
        ...prevState.DataTables,
        perPage: perPage,
        inPage: inPage,
        pending: true,
        startDate: start,
        endDate: end,
      },
    }));
    getData(
      perPage,
      inPage,

      start,
      end
    );
  };

  const paginationChange = (inPage, perPage, start = "", end = "") => {
    setState((prevState) => ({
      ...prevState,
      DataTables: {
        ...prevState.DataTables,
        perPage: perPage,
        inPage: inPage,
        pending: true,
        startDate: start,
        endDate: end,
      },
    }));
    getData(inPage, perPage, start, end);
  };

  const filterHandler = (inPage, perPage, start = "", end = "") => {
    setState((prevState) => ({
      ...prevState,
      DataTables: {
        ...prevState.DataTables,
        perPage: 10,
        inPage: 1,
        pending: true,
        startDate: start,
        endDate: end,
      },
    }));
    getData(state.DataTables.inPage, state.DataTables.perPage, start, end);
  };

  const resetData = (start, end) => {
    paginationChange(
      state.DataTables.inPage,
      state.DataTables.perPage,
      moment().startOf("month").format("YYYY-MM-DD"),
      "",
      "",
      ""
    );
  };
  const ConverLang = (data) => {
    let NewData = data.map((obj, key) => {
      return {
        description: t("ewallet." + obj.description1.langCode, {
          username: obj.description1?.user,
          type:
            obj.description1?.fee_type == "fundtransfer"
              ? "( " + t("ewallet.transaction_fee")
              : "( " + t("ewallet.payout_fee"),
          fee:
            obj.description1?.fee &&
            "-" + parseFloat(obj.description1?.fee).toFixed(2) + " )",
          Currency: obj.description1?.currency,
          loadType: obj.description1?.loadType,
        }),
        amount:
          Currency.currentCurr +
          (obj.amount * Currency.value).toFixed(Currency.precision),
        balance:
          Currency.currentCurr +
          (obj.balance * Currency.value).toFixed(Currency.precision),
        transaction_date: obj.transaction_date,
      };
    });
    return NewData;
  };

  const exportData = (type, fileName) => {
    service.exportData("", "", "", "").then((res) => {
      let fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let fileExtension = ".xlsx";
      if (type === "excel") {
        const ws = XLSX.utils.json_to_sheet(ConverLang(res.data.table_data));
        XLSX.utils.sheet_add_aoa(ws, [
          ["Description", "Amount", "Balance", "Transaction Date"],
        ]);

        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
      }
      if (type === "csv") {
        fileExtension = ".csv";
        let fileType = "text/csv;charset=utf-8";
        const ws = XLSX.utils.json_to_sheet(ConverLang(res.data.table_data));
        XLSX.utils.sheet_add_aoa(ws, [
          ["Description", "Amount", "Balance", "Transaction Date"],
        ]);
        const csvOutput = XLSX.utils.sheet_to_csv(ws);
        const data = new Blob([csvOutput], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
      }
    });
  };

  const addCurrency = (data) => {
    let NewData = data.map((obj) => {
      if (obj) {
        return {
          ...obj,
          total_amount:
            Currency.currentCurr +
            (obj.total_amount * Currency.value).toFixed(Currency.precision),
          service_charge:
            Currency.currentCurr +
            (obj.service_charge * Currency.value).toFixed(Currency.precision),
        };
      } else return obj;
    });
    return NewData;
  };

  if (state.loading) {
    return <Spinner />;
  }
  return (
    <div className={`h-100`}>
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.ewalletReport")}
        </title>
      </Helmet>
      <PageTitle title="ewalletReport" buttonOn={false} />
      <div className={style.MainContainer} style={style}>
        <StatmentReport
          {...state}
          handlePerRowsChange={handlePerRowsChange}
          paginationChange={paginationChange}
          filterHandler={filterHandler}
          resetData={resetData}
          exportData={exportData}
          totalRows={state.DataTables.totalRows}
          DataTables={state.DataTables}
          printData={state.printData}
        />
      </div>
    </div>
  );
};
