import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../components/common/pageTitle';
import Spinner from '../../shared/Spinner';
import { withRouter } from 'react-router';
import { GenealogyTreeContent } from '../../components/network';
import GenealogyService from '../../../service/tree/genealogy';
import companyName from '../../../store/mobxStore/companyName';


const GenealogyTree = (props) => {

    
    const [state, setState] = useState({
        loading: true,
        data: [],
        tooltipConfig: {},
        storeUrl: "",
        error: {
            show: false,
            message: ''
        },
        serachloading:false,
        resetloading:false,

    }

    )

    const service = new GenealogyService();

    useEffect(() =>{
        const { userName } = props.match.params?props.match.params:'';
        getlist(userName);
      
    },[])


  const  getlist = (username = '', parentId = "") => {
        service.getTreelist(username, parentId).then(res => {
            if (res.status) {
                setState({
                    loading: false,
                    data: res.data.TreeData,
                    tooltipConfig: res.data.tooltip_config,
                    storeUrl: res.data.store_url
                });
            } else {
                //if the invalid access tocken
                if (res.error.code === 1002) {
                    props.history.push('/logout')
                } else if (res.error.code === 1043) {
                    props.history.push({
                        pathname: '/genealogyTree',
                        state: {
                            error: true,
                            message: 'InvalidUserName'
                        }
                    })
                }
            }
        })

    }

    //filter the list
 const   filterUser = (username) => {
        getlist(username,'','loader')
    }


 const   parentNode = (parentId) => {
        getlist('', parentId);
    }
    const { t } = props;
   
   
    return (


  <div>
    
   { state.loading ?
        <Spinner />
   :( <React.Suspense fallback={<Spinner />}>
    <Helmet>
        <title>{companyName.companyName} | {t('Sidemenu.genealogyTree')}</title>
    </Helmet>
    <PageTitle title="genealogyTree" buttonOn={false} />
    <GenealogyTreeContent
        filterUser={filterUser}
        getList={getlist}
        parentNode={parentNode}
        {...state} />
</React.Suspense>)}

       
    
        </div>
    )
}

export default withTranslation()((withRouter(GenealogyTree)))