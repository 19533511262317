import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import Helmet from "react-helmet";
import companyName from "../../../store/mobxStore/companyName";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import moment from "moment";
import { connect } from "react-redux";
//components
import PageTitle from "../../components/common/pageTitle";
import Spinner from "../../shared/Spinner";
import { useSelector } from "react-redux";
//service
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useHistory } from "react-router";
import UserStore from "../../../store/UserStore";
import Tiles from "../../components/rewards/Tiles";
import ReportService from "../../../service/reports/reports";
import ConsumerRewards from "../../components/rewards/ConsumerRewards";
import style from "../../components/rewards/reward.module.scss";
import { consumerCare, exportData } from "../../../service/rewards/rewards";

const mapStateToProps = (state) => ({ lang: state.Lang });

const ConsumerCareReport = (props) => {
  const service = new ReportService();
  const Currency = useSelector((state) => state.curr);
  const { t } = props;
  const [checkout, setCheckout] = useState(false);
  const [EwalletAmount, setEwalletAmount] = useState({
    amount: "",
    FormError: {
      amount: "",
    },
    isvalid: false,
  });

  const [loader, setLoader] = useState({
    EarningsTable: {
      search: false,
      reset: false,
      excel: false,
      csv: false,
      print: false,
    },
    HistoryTable: {
      search: false,
      reset: false,
    },
  });

  const history = useHistory();

  const changeHandler = (e) => {
    const { name, value } = e.target;

    setEwalletAmount((prev) => ({
      ...prev,
      [name]: value,
      FormError: {
        ...prev.FormError,
        [name]: "",
      },
      isvalid: false,
    }));
  };

  const [state, setState] = useState({
    loading: true,
    DataTables: {
      totalRows: 0,
      data: [],
      pending: true,
      perPage: 10,
      inPage: 1,
      startDate: moment().startOf("month").format("MMMM D, YYYY"),
      endDate: moment().format("MMMM D, YYYY"),
    },
    tilesData: [],
    printData: []
  });

  useEffect(() => {
    loadReport();
  }, [Currency.currentCurr]);

  //load the data
  const loadReport = (
    inPage = state.DataTables.inPage,
    perPage = state.DataTables.perPage,
    startDate = state.DataTables.startDate,
    endDate = state.DataTables.endDate,
  ) => {
    let start = (inPage - 1) * perPage;
    consumerCare(start, perPage, startDate, endDate)
      .then((res) => {
        if (res.status) {
          setState((prevState) => ({
            ...prevState,
            loading: false,
            tilesData: res.data.totalpoints,
            DataTables: {
              ...prevState.DataTables,
              data: res.data.result,
              totalRows: res.data.count,
              perPage: 10,
              inPage: 1,
              pending: true,
            },
          }));
        } else {
          props.history.push("/logout");
        }
      });
  };

  const filterHandler = (inPage, perPage, start = "", end = "",) => {
    setState((prevState) => ({
      ...prevState,
      DataTables: {
        ...prevState.DataTables,
        perPage: 10,
        inPage: 1,
        pending: true,
        startDate: start,
        endDate: end,
      },
    }));
    loadReport(
      state.DataTables.inPage,
      state.DataTables.perPage,
      start,
      end,
      
    );
  };
  const handlePerRowsChange = (
    inPage,
    perPage,
    start = "",
    end = "",
  ) => {
    setState((prevState) => ({
      ...prevState,
      DataTables: {
        ...prevState.DataTables,
        perPage: perPage,
        inPage: inPage,
        pending: true,
        startDate: start,
        endDate: end,
      },
    }));
    loadReport(
      perPage,
      inPage,
      start,
      end,
    );
  };

  const paginationChange = (
    inPage,
    perPage,
    start = "",
    end = "",
    key = ""
  ) => {
    setState((prevState) => ({
      ...prevState,
      DataTables: {
        ...prevState.DataTables,
        perPage: perPage,
        inPage: inPage,
        pending: true,
        startDate: start,
        endDate: end,
      },
    }));
    loadReport(inPage, perPage, start);
  };

  const addCurrency = (data) => {
    let NewData = data.map((obj) => {

      if (obj) {
        return {
          ...obj,
          ConsumerCareRewards: Currency.currentCurr + (obj.ConsumerCareRewards * Currency.value).toFixed(
            Currency.precision
          ),
          infinitereward: Currency.currentCurr + (obj.infinitereward * Currency.value).toFixed(
            Currency.precision
          )
        }
      }
      else return obj
    })
    return NewData;


  }


  const exportItems = (type, fileName) => {
    exportData(state.DataTables.startDate).then((res) => {
      let fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let fileExtension = ".xlsx";
      if (type === "excel") {
        const ws = XLSX.utils.json_to_sheet(addCurrency(res.data.result));
        XLSX.utils.sheet_add_aoa(ws, [
          [
            "MONTH",
            "NAME",
            "INFINITE REWARDS",
            "CONSUMER CARE REWARDS"
          ],
        ]);

        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
      }
      if (type === "csv") {
        fileExtension = ".csv";
        let fileType = "text/csv;charset=utf-8";
        const ws = XLSX.utils.json_to_sheet(addCurrency(res.data.result));
        XLSX.utils.sheet_add_aoa(ws, [
          [
            "MONTH",
            "NAME",
            "INFINITE REWARDS",
            "CONSUMER CARE REWARDS"
          ],
        ]);
        const csvOutput = XLSX.utils.sheet_to_csv(ws);
        const data = new Blob([csvOutput], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
      }

      if (type === "print") {
        setState({
          ...state,
          printData: res.data.result
        })

        // ExportToExcel(res.data, "User Earnigs Report", type);
      }

      // if(type==='excel'){
      //     fileDownload(res, 'User Earnigs Report.xlsx');
      // }
    });


  };
  return (
    <div className={`h-100`}>
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.consumer_care_rewards")}
        </title>
      </Helmet>
      <PageTitle title="consumer_care_rewards" buttonOn={false} />
      <div className={style.MainContainer}>
        {state.loading ? (
          <Spinner />
        ) : (
          <>
            {state.tilesData &&
              <Tiles tiles={state.tilesData} />
            }
            <ConsumerRewards
              tableData={state.DataTables.data}
              ContentData={state.DataTables}
              rowsCount={state.DataTables.totalRows}
              filterHandler={filterHandler}
              handlePerRowsChange={handlePerRowsChange}
              paginationChange={paginationChange}
              exportData={exportItems}
              printData={state.printData}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(
  connect(mapStateToProps)(withRouter(ConsumerCareReport))
);
