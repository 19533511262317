import React, { useEffect, useState } from "react";
import EwalletService from "../../../service/ewallet/Ewallet";
import Table from "react-bootstrap/Table";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//service
import AppInfoservice from "../../../service/common/Appinfo";

//style
import { Row, Col } from "react-bootstrap";
import ReportService from "../../../service/reports/reports";

export class ComponentToPrint extends React.PureComponent {
  constructor(props) {
    super(props);
    this.service = new ReportService();

    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()} , ${current.getHours()}:${current.getMinutes()}`;
    // const time = current.getHours() + ':' + current.getMinutes();

    this.state = {
      printData: [],
      loading: true,
      date: date,
    };
  }

  exportDatas(type) {
    this.service.exportVendorSalesReport().then((res) => {
      if (res.status) {
        this.setState((prevState) => ({
          ...prevState,
          loading: false,
          printData: res.data.result,
        }));
      } else {
        this.setState((prevState) => ({
          ...prevState,
          loading: true,
        }));
      }
    });
  }

  componentDidUpdate(prevProps) {
    // if (
    //   this.props.data.filterValues != prevProps.data.filterValues ||
    //   this.props.data.startDate != prevProps.data.startDate ||
    //   this.props.data.endDate != prevProps.data.endDate
    // ) {
    //   this.exportDatas("print");
    // }
  }
  componentDidMount() {
    this.exportDatas("print");
  }

  render() {
    return (
      <div style={{ padding: 60 }}>
        {console.log("The printdata in print page is ", this.props)}
        <Row>
          <Col>{this.state.date}</Col>
          <Col>
            <h5 style={{ textAlign: "center" }}>Vendor Sales Report</h5>
          </Col>
        </Row>

        <div ref={this.ref}>
          <Row style={{ marginBottom: 50 }}>
            <Col span={8}>
              {/* <img src={this.props.company.logo} alt="Logo" /> */}
              <div>COMPANY NAME : {this.props.company.comapny_name}</div>
              <div>COMPANY ADDRESS : {this.props.company.company_address}</div>
              <div>TELEPHONE : {this.props.company.phone}</div>
              <div>EMAIL : {this.props.company.email}</div>
            </Col>
          </Row>

          {!this.state.loading && this.state.printData.length > 0 && (
            <Table striped hover>
              <thead>
                <tr>
                  <th>{this.props.t("Common.date")}</th>
                  <th>{this.props.t("Common.name")}</th>
                  <th>{this.props.t("Common.TransNo")}</th>
                  <th>{this.props.t("Common.amount")}</th>
                  <th>{this.props.t("Common.withoutvat")}</th>
                  <th>{this.props.t("Common.vat")}</th>
                  <th>{this.props.t("Common.vendorshare")}</th>
                </tr>
              </thead>
              <tbody>
                {this.state.printData.map((obj, index) => {
                  {
                    /* {this.props.ContentData &&  this.props.ContentData.map((obj, index) => { */
                  }
                  return (
                    <tr key={index}>
                      <td>{obj.date}</td>
                      <td>{obj.name}</td>
                      <td>{obj.id}</td>
                      <td>{obj.total}</td>
                      <td>{obj.totalWithOutVat}</td>
                      <td>{obj.vat}</td>
                      <td>{obj.vendorShare}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </div>
      </div>
    );
  }
}

const VendorReportPrintView = React.forwardRef((props, ref) => {
  const Currency = useSelector((state) => state.curr);
  const { t } = useTranslation();

  const [companyDetails, setCompanyDetails] = useState({});

  const service = new AppInfoservice();

  useEffect(() => {
    service.Appinfo().then((res) => {
      if (res.status) {
        setCompanyDetails((prevState) => ({
          ...prevState,
          // logo: res.data.company_info.login_logo,
          comapny_name: res.data.company_info.company_name,
          company_address: res.data.company_info.company_address,
          phone: res.data.company_info.phone,
          email: res.data.company_info.email,
        }));
      }
    });
  }, []);
  return (
    <ComponentToPrint
      ref={ref}
      data={props}
      company={companyDetails}
      Currency={Currency}
      t={t}
    />
  );
});
export default VendorReportPrintView;
